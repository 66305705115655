import React, { useState } from 'react'
import { Arrow } from '../components/AllIcons'
import Footer from '../components/Footer'
import HeaderCard from '../components/HeaderCard'
import { ROOT_URL } from '../utils/rootURL'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export default function ForgotPassword() {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const onForgot = () => {
        if (!email) {
            alert('please enter email')
            return;
        }
        const inputData = {
            email: email,
        };
        axios.post(`${ROOT_URL}/api/forgot_password`, inputData).then(res => {
            if (res.data.status === 'success') {
                navigate('/resetpassword', { state: res?.data.email })
            } else {
                setErrorMessage(res.data.msg)
            }
        }).catch(err => {
            console.log(err)
        })
    }


    return (
        <>
            <HeaderCard />
            <main className='main'>
                <div className='game_card '>
                    <div className='d-lg-flex align-items-center'>
                        <div className='login_card'>
                            <div className='text-center account_logo'>
                                <img src='assets/img/image/login/forgot.svg' />
                            </div>
                        </div>
                        <div className='login_card bg_white'>
                            <div className='title_card mb-3'>
                                <h2 className='text_dark'>Forgot Password?</h2>
                            </div>
                            <p className='list_p'>No worries! Enter your email and we will send you a link to reset your password</p>
                            <form className='form_space'>
                                <div className='login_form'>
                                    <input type='text' className='form-control input_box' name='email' onChange={(e) => setEmail(e.target.value)} required />
                                    <label className='form-lable'>Email</label>
                                    {errorMessage &&
                                        <p className='note_massage'>{errorMessage}</p>
                                    }
                                </div>
                            </form>
                            <button type='button' className='btn sign_btn' onClick={() => onForgot()}>Reset Password</button>
                            <div className='d-flex align-items-center justify-content-center gap-2 mt-3'>
                                <Arrow />
                                <a href='login' className='page_link list_p'>Back to sign in</a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </>
    )
}
