import axios from "axios"
import { ROOT_URL } from "../../utils/rootURL"
import { setUserData, setUserDataError, setUserDataLoading } from "../slice/userData"

export const getUserData = () => {
    return dispatch => {
        dispatch(setUserDataLoading(true))
        axios.get(
            `${ROOT_URL}/api/Get_User_Data `,
            {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            }
        ).then(res => {
            dispatch(setUserData(res.data?.result || {}))
        }).catch(err => {
            dispatch(setUserDataError(err))
        })
    }
}