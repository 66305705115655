import React, { useEffect, useState } from 'react'
import HeaderCard from '../components/HeaderCard'
import Footer from '../components/Footer'
import { Link, useLocation } from 'react-router-dom'
import { ROOT_URL } from '../utils/rootURL'
import axios from 'axios'
import Loader from '../components/Loader'

export default function AllGame() {

    console.log('datatatatatataat')

    const { state } = useLocation()
    const [gameList, setGameList] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        if (state) {
            setIsLoading(true)
            axios.get(`${ROOT_URL}/games/catagory/${state?.Categoryname}`).then(res => {
                if (res.status === 200) {
                    setGameList(res.data.data)
                    setIsLoading(false)

                }
            }
            ).catch(err => {
                console.log(err, 'eerrrorororo')
                setIsLoading(false)
            })

        }

    }, [state])

    return (
        <>
            <HeaderCard />
            <main className='main'>

                <div className='allgame_title'>
                    <h2>{state?.Categoryname}</h2>
                </div>

                <div className={`game_card ${gameList ? '' : 'd-flex align-items-center justify-content-center'}`}>
                    <div className={`bike_g_card ${gameList ? '' : 'justify-content-center'}`}>
                        {isLoading ?
                            <Loader />
                            :
                            gameList ?
                                gameList?.map((i, index) =>
                                    <Link to={`/games/${i?.game_name.replaceAll(" ", "-").toLowerCase()}`} state={i} key={index}>
                                        <div className="new_games_card" style={{ backgroundImage: `url(${i?.Image_url})` }}>
                                            <div className="hexa-match_contant d-flex justify-content-between w-100 p-3 fw-bolder">
                                                <div className="hexa-text ">
                                                    <p className="m-0"> {i.game_name}</p>
                                                    <div className="player d-flex">
                                                        <img src="/assets/img/image/game-controller.svg" alt='game' />
                                                        <p className="m-0">9.4M Played</p>
                                                    </div>
                                                </div>
                                                <div className="play-button d-flex align-items-center ">
                                                    <p className="m-0">Play Now</p>
                                                    <img src="/assets/img/image/play_controller.svg" alt='game' />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                )
                                :
                                <h3 className='data_not_found'>Games Not Found</h3>

                        }


                    </div>

                </div>
                <Footer />
            </main>
        </>
    )
}
