import React from 'react'
import { CoinIcon } from './AllIcons'
import { Link } from 'react-router-dom'

export default function Coinhistory() {
    return (
        <>
            <div className='coin_hitory'>
                <div className='card_heading card_space mb-0'>
                    <div className='title_card coin'>
                        <CoinIcon/>
                        <h2 className='text_dark'>Coin History</h2>
                    </div>
                    <Link to='' className='view_link view_text'>View All</Link>
                </div>
                <div className='table-reponsive'>
                    <table className='table table_history mb-0'>
                        <thead>
                            <tr>
                                <th>Time</th>
                                <th>Game Name</th>
                                <th className='text-end'>Coins</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>18 April, 2024 <span className='text_gray'>06:26pm</span></td>
                                <td>Bad Ice Cream 3</td>
                                <td className='text-end'>
                                    <span className='text_green'>+20</span> Coins
                                </td>
                            </tr>
                            <tr>
                                <td>18 April, 2024 <span className='text_gray'>06:26pm</span></td>
                                <td>Bad Ice Cream 3</td>
                                <td className='text-end'>
                                    <span className='text_green'>+20</span> Coins
                                </td>
                            </tr>
                            <tr>
                                <td>18 April, 2024 <span className='text_gray'>06:26pm</span></td>
                                <td>Bad Ice Cream 3</td>
                                <td className='text-end'>
                                    <span className='text_green'>+20</span> Coins
                                </td>
                            </tr>
                            <tr>
                                <td>18 April, 2024 <span className='text_gray'>06:26pm</span></td>
                                <td>Bad Ice Cream 3</td>
                                <td className='text-end'>
                                    <span className='text_green'>+20</span> Coins
                                </td>
                            </tr>
                            <tr>
                                <td>18 April, 2024 <span className='text_gray'>06:26pm</span></td>
                                <td>Bad Ice Cream 3</td>
                                <td className='text-end'>
                                    <span className='text_green'>+20</span> Coins
                                </td>
                            </tr>
                            <tr>
                                <td>18 April, 2024 <span className='text_gray'>06:26pm</span></td>
                                <td>Bad Ice Cream 3</td>
                                <td className='text-end'>
                                    <span className='text_green'>+20</span> Coins
                                </td>
                            </tr>
                            <tr>
                                <td>18 April, 2024 <span className='text_gray'>06:26pm</span></td>
                                <td>Bad Ice Cream 3</td>
                                <td className='text-end'>
                                    <span className='text_green'>+20</span> Coins
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
