import React, { useState } from 'react'
import Header from '../components/Header'
import Category from '../components/Category'
import { Google, NewGameIcon, Person, PersonF, Success, True } from '../components/AllIcons'
import Card from '../components/Card'
import Footer from '../components/Footer'
import HeaderCard from '../components/HeaderCard'
import { EMAIL_REGEX, PASSWORD_REGEX } from '../utils/constants'
import { ROOT_URL } from '../utils/rootURL'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'

export default function ResetPassword() {
    const { state } = useLocation()

    const navigate = useNavigate()
    const [input, setInput] = useState({
        otp: '',
        password: '',
        confirmpassword: ''
    })
    const [validation, setValidation] = useState({
        otp: false,
        password: false,
        confirmpassword: false
    })
    const validationRules = {
        otp: 'OTP Required.',
        password: 'password Required.',
        confirmpassword: 'Confirm password Required',
    };
    const [errorMessage, setErrorMessage] = useState('')
    const handelInputs = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value })
        setValidation({ ...validation, [e.target.name]: false })
    }

    const checkValidation = () => {
        for (const field in input) {
            if (!input[field]) {
                setValidation({ ...validation, [field]: true })
                setErrorMessage(validationRules[field])
                return;
            }

            if (field === 'password' && !PASSWORD_REGEX.test(input.password)) {
                setValidation({ ...validation, password: true });
                setErrorMessage('Valid Password Required.')
                return;
            }
            if (field === 'confirmpassword' && !PASSWORD_REGEX.test(input.confirmpassword)) {
                setValidation({ ...validation, confirmpassword: true });
                setErrorMessage('Password Not Match.')
                return;
            }
        }
        onUpdatePassword()
    }

    const onUpdatePassword = () => {
        // var bodyData = new FormData();
        // bodyData.append('username', input.username)
        // bodyData.append('email', input.email)
        // bodyData.append('password', input.password)
        // bodyData.append('cpassword', input.confirmpassword)

        const inputData = {
            email: state,
            otp: input.otp,
            password: input.password,
        };

        axios.post(`${ROOT_URL}/api/reset_password`, inputData).then(res => {
            if (res.data.status === 'success') {
                navigate('/login')
            } else {
                setErrorMessage(res.data.msg)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <>
            <HeaderCard />
            <main className='main'>
                <div className='game_card'>
                    <div className='d-lg-flex align-items-center'>
                        <div className='login_card'>
                            <div className='text-center mb-3 account_logo'>
                                <img src='assets/img/image/login/signup.svg' />
                            </div>
                            <div className='title_card space_mb'>
                                <h2 className='text_dark'>Create New Account</h2>
                            </div>
                            <p className='list_p space_mb'>Create new account with our website for free to avail of all the amazing benefits of the registered players in the form of awards, statistics, your own avatar, high scores and much more!</p>
                            <div className='sign_list'>
                                <Success />
                                <p className='list_p'>the complete games statistics like Leaderboard, high scores, and rankings..</p>
                            </div>
                            <div className='sign_list'>
                                <Success />
                                <p className='list_p'>Get latest update of launching any new game.</p>
                            </div>
                            <div className='sign_list'>
                                <Success />
                                <p className='list_p'>keep you engrossed for hours</p>
                            </div>
                            <div className='sign_list'>
                                <Success />
                                <p className='list_p'>an extensive variety of free online games.</p>
                            </div>
                        </div>
                        <div className='login_card bg_white flex_card card_gap'>
                            {/* <div className='d-flex align-items-center gap-2'>
                                <div className='check_form'>
                                    <input type='radio' className='form-check-input select_form' />
                                    <div className='check_label d-flex align-items-center'>
                                        <Person />
                                        <p className='list_p'>Male</p>
                                    </div>
                                </div>
                                <div className='check_form'>
                                    <input type='radio' className='form-check-input select_form' />
                                    <div className='check_label d-flex align-items-center'>
                                        <PersonF />
                                        <p className='list_p'>Female</p>
                                    </div>
                                </div>
                            </div> */}
                            <form>
                                <div className='login_form mt-3'>
                                    <input type='text' className='form-control input_box' name='email' value={state} disabled />
                                    <label className='form-lable'>Email</label>
                                </div>
                                <div className='login_form mt-3'>
                                    {validation.otp &&
                                        <p className='note_massage massage_set'>{errorMessage}</p>}
                                    <input type='text' className='form-control input_box' name='otp' maxLength={6} min={6} onChange={handelInputs} required />
                                    <label className='form-lable'>OTP</label>
                                </div>
                                <div className='login_form mt-3'>
                                    {validation.password &&
                                        <p className='note_massage massage_set'>{errorMessage}</p>}
                                    <input type='password' className='form-control input_box' name='password' onChange={handelInputs} required />
                                    <label className='form-lable'>Password</label>
                                </div>
                                <div className='login_form mt-3'>
                                    {validation.confirmpassword &&
                                        <p className='note_massage massage_set'>{errorMessage}</p>}
                                    <input type='password' className='form-control input_box' name='confirmpassword' onChange={handelInputs} required />
                                    <label className='form-lable'>Confirm Password</label>
                                </div>
                            </form>
                            <button type='button' className='btn sign_btn' onClick={() => checkValidation()}>Create Account </button>
                            <div className='d-flex align-items-center justify-content-center gap-1'>
                                <p className='list_p'>Already have a profile?</p>
                                <a href='login' className='page_link list_p'>Sign in</a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </>
    )
}
