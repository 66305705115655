import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGamesCategory } from '../redux/actions/GamesCategoryAction'
import { Link } from 'react-router-dom'
import { ROOT_URL } from '../utils/rootURL'
import axios from 'axios'
import Loader from './Loader'

export default function BikeGames({ category }) {

    const [sportGame, setSportGame] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        setIsLoading(true)

        axios.get(`${ROOT_URL}/games/catagory${category}`).then(res => {
            if (res.status === 200) {
                setSportGame(res.data.data)
                setIsLoading(false)

            }
        }
        ).catch(err => {
            console.log(err, 'eerrrorororo')
            setIsLoading(false)

        }
        )
    }, [])


    // const dispatch = useDispatch()

    // useEffect(() => {
    //     dispatch(getGamesCategory(category))
    // }, [category])

    // const { data: sports } = useSelector(state => state.GamesCategory)

    return (
        <>
            <div className={`bike_g_card ${sportGame ? '' : 'd-flex align-items-center h-100 justify-content-center'}`}>
                {isLoading ? <Loader /> : sportGame.length ?
                    sportGame.map((i, index) =>
                        <Link to='' key={index}>
                            <div className="new_games_card" style={{ backgroundImage: `url(${i?.Image_url})` }}>
                                <div className="hexa-match_contant d-flex justify-content-between w-100 p-3 fw-bolder">
                                    <div className="hexa-text ">
                                        <p className="m-0"> {i?.game_name}</p>
                                        <div className="player d-flex">
                                            <img src="/assets/img/image/game-controller.svg" alt='game' />
                                            <p className="m-0">9.4M Played</p>
                                        </div>
                                    </div>
                                    <div className="play-button d-flex align-items-center ">
                                        <p className="m-0">Play Now</p>
                                        <img src="/assets/img/image/play_controller.svg" alt='game' />
                                    </div>
                                </div>
                            </div>
                        </Link>
                    )
                    :
                    <h3 className='data_not_found'>Games Not Found</h3>
                }
            </div>
        </>
    )
}
