import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './page/Home';
import Login from './page/Login';
import SignUp from './page/signUp';
import ForgotPassword from './page/ForgotPassword';
import PrivacyPolicy from './page/PrivacyPolicy';
import TermsCondition from './page/TermsCondition';
import About from './page/About';
import GamePlay from './page/GamePlay';
import ResetPassword from './page/ResetPassword';
import Authe from './components/Authe';
import AllGame from './page/AllGame';
import ScrollToTop from './components/ScrollToTop';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getUserData } from './redux/actions/userDataAction';
import Blog from './page/Blog';
import BlogDetails from './page/BlogDetails';
import CoinHistory from './page/CoinHistory';
import Dashboard from './page/Dashboard';
import LeaderBoard from './page/LeaderBoard';
import Profile from './page/Profile';
import EditProfile from './page/EditProfile';
import ContactUs from './page/ContactUs';
import AutheLogin from './components/AutheLogin';

function App() {

  const dispatch = useDispatch()

  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      dispatch(getUserData())
    }
  }, [])

  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/games/:id' element={<GamePlay />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-condition' element={<TermsCondition />} />
            <Route path={'/online-games/:id'} element={<AllGame />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/blog/:id' element={<BlogDetails />} />
            <Route path='/contact-us' element={<ContactUs />} />

            <Route element={<AutheLogin />}>
              <Route path='/profile' element={<Profile />} />
              <Route path='/edit-profile' element={<EditProfile />} />
              <Route path='/coin-history' element={<CoinHistory />} />
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/leaderboard' element={<LeaderBoard />} />
            </Route>

            <Route element={<Authe />}>
              <Route path='/login' element={<Login />} />
              <Route path='/Signup' element={<SignUp />} />
              <Route path='/forgotpassword' element={<ForgotPassword />} />
              <Route path='/resetpassword' element={<ResetPassword />} />
            </Route>
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
