import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getGamesCategory } from '../redux/actions/GamesCategoryAction'
import axios from 'axios'
import { ROOT_URL } from '../utils/rootURL'
import Loader from './Loader'

export default function NewGame({ category }) {

    const [newGame, setNewGame] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        axios.get(`${ROOT_URL}/games/catagory${category}`).then(res => {
            if (res.status === 200) {
                setNewGame(res.data.data)
                setIsLoading(false)

            }
        }
        ).catch(err => {
            console.log(err, 'eerrrorororo')
            setIsLoading(false)

        }
        )
    }, [])

    // const dispatch = useDispatch()

    // useEffect(() => {
    //     dispatch(getGamesCategory(category))
    // }, [category])

    // const { data: newgame } = useSelector(state => state.GamesCategory)

    return (

        <div className={`card_grid ${newGame ? '' : 'd-flex align-items-center justify-content-center'}`}>
            {isLoading ? <Loader /> :
                newGame.length ?
                    newGame.map((i, index) =>
                        <Link to={`/games/${i?.game_name}`} state={i} key={index} >

                            <div className="new_games_card" style={{ backgroundImage: `url(${i?.Image_url})` }}>
                                <div className="hexa-match_contant d-flex justify-content-between w-100 p-3 fw-bolder">
                                    <div className="hexa-text ">
                                        <p className="m-0"> {i?.game_name}</p>
                                        <div className="player d-flex">
                                            <img src="/assets/img/image/game-controller.svg" alt='game' />
                                            <p className="m-0">9.4M Played</p>
                                        </div>
                                    </div>
                                    <button state={i} className="play-button d-flex align-items-center " >
                                        <p className="m-0">Play Now</p>
                                        <img src="/assets/img/image/play_controller.svg" alt='game' />
                                    </button>
                                </div>
                            </div>
                        </Link>
                    )
                    :
                    <h3 className='data_not_found'>Games Not Found</h3>
            }
        </div >
    )
}
