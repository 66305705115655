import React, { useEffect, useState } from 'react'
import { SwiperSlide, Swiper } from "swiper/react";
import { FreeMode } from "swiper/modules";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import axios from 'axios';
import { ROOT_URL } from '../utils/rootURL';

export default function MostGames() {

  const [mostGames, setMostGames] = useState([])
  const [loading, setLoading] = useState(true)

  const getMostGames = async () => {
    try {
      const res = await axios.get(`${ROOT_URL}/games/catagory/TOP_PLAY`)
      setMostGames(res.data?.data || [])
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getMostGames()
  }, [])

  return (
    <>
      <Swiper
        slidesPerView={13.5}
        spaceBetween={10}
        freeMode={true}
        modules={[FreeMode]}
        breakpoints={{
          320: {
            slidesPerView: 2.8,
          },
          345: {
            slidesPerView: 3.2,
          },
          400: {
            slidesPerView: 3.5,
          },
          576: {
            slidesPerView: 3.5,
          },

          768: {
            slidesPerView: 4.9,
          },
          840: {
            slidesPerView: 5.5,
          },
          1024: {
            slidesPerView: 6.5,
          },
          1200: {
            slidesPerView: 8.5,
          },
          1400: {
            slidesPerView: 9.5,
          },
          1540: {
            slidesPerView: 10.5,
          },
          1640: {
            slidesPerView: 11.5,
          },
          1740: {
            slidesPerView: 12.5,
          },
        }}
        className="mySwiper"
      >
        {
          loading ? <div className='text-center'>Loading...</div> :
            mostGames.length ? mostGames.map((i, index) =>
              <SwiperSlide key={index}>
                <a href={i.Game_url || ''}>
                  <div className="new_games_card most_games_card" style={{ backgroundImage: `url(${i.Image_url})` }}>
                  </div>
                </a>
              </SwiperSlide>
            )
              : <div>No data found</div>
        }
      </Swiper>
    </>
  )
}
