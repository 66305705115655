import React from 'react'
import OverViewCards from '../components/OverViewCards'
import SideMenu from '../components/SideMenu'
import HeaderCard from '../components/HeaderCard'
import Footer from '../components/Footer'



export default function Dashboard() {
    return (
        <>
            <HeaderCard />
            <main className='main'>
                <div className='page_layout'>
                    <SideMenu />
                    <div className='w-100'>
                        <OverViewCards />                       
                    </div>
                </div>
                <Footer />
            </main>
        </>
    )
}
