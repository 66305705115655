import React from 'react'
import Category from './Category'
import Header from './Header'

export default function HeaderCard() {
    return (
        <>
            <div className='gradiunt_card header_card'>
                <header className='header'>
                    <Header />
                </header>
                <div className='catagory'>
                    <Category />
                </div>
            </div>
        </>
    )
}
