import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Category from '../components/Category'
import HeaderCard from '../components/HeaderCard'

export default function PrivacyPolicy() {
    return (
        <>
           <HeaderCard/>
            <main className='main'>
                <div className='container'>
                    <div className='policy'>
                        <h1>Privacy Policy for "Future Fusion"</h1>
                        <p>Effective as of April 16, 2024</p>
                        <div className='mb-3'>
                            <h3>1. Definitions </h3>
                            <p>We, Our, and Us refer to the creators of this privacy policy.
                                You, Your, and User refer to natural and legal individuals utilizing the "Future Fusion" website.
                            </p>
                            <p>Personal information encompasses any personally identifiable information collected from users.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>2. Information We Collect </h3>
                            <p>Committed to respecting online privacy, we collect information such as:</p>
                            <p><strong>1</strong> Personal data (Name, Location, Email ID, etc.)</p>
                            <p><strong>2</strong> Tracking Information (IP addresses, Device ID)</p>
                            <p><strong>3</strong> data from both registered and non-registered users, including browsing behavior and page interactions.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>3. Login System </h3>
                            <p>Operating a custom login system and utilizing Facebook login, we ensure data security through India-based servers. By providing information, users consent to data processing and transfer as required.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>4. Our Use of Your Information </h3>
                            <p>Collected information is utilized for communication, marketing, service enhancement, and record-keeping. We prioritize data security and relevance, ensuring it's used lawfully and solely for necessary purposes.
                            </p>
                        </div>
                        <div className='mb-3'>
                            <h3>5. How Information Is Collected</h3>
                            <p>Information collection is transparent and purpose-driven, with emphasis on lawful methods and user consent. Data retention aligns with the specified purposes and legal requirements.
                            </p>
                        </div>
                        <div className='mb-3'>
                            <h3>6. Cookies </h3>
                            <p>Our website utilizes cookies for customization and functionality, allowing users to control preferences through browser settings. We provide options for cookie acceptance or opt-out.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>7. Google Analytics</h3>
                            <p>Employing Google Analytics for data analysis and user behavior understanding, we enhance user experience and offer personalized content based on preferences.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>8. External Links on the Website </h3>
                            <p>External links, advertisements, and resources on the site are not within our control. Users are advised to review the privacy policies of external websites independently for data protection practices.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>9. Your Rights </h3>
                            <p>Users possess rights to access, correct, withdraw consent, object, and lodge complaints regarding their personal data. We respect user preferences and ensure data confidentiality.
                            </p>
                        </div>
                        <div className='mb-3'>
                            <h3>10. Confidentiality</h3>
                            <p>User information is treated as confidential and is not disclosed without consent except where legally demanded. Communication channels are secure, and access is restricted to authorized personnel.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>11. Other Information Collectors </h3>
                            <p>Our Privacy Policy pertains only to data we collect. Users are advised to review privacy policies of third-party websites for separate procedures regarding data collection and use.
                            </p>
                        </div>
                        <div className='mb-3'>
                            <h3>12. Our Disclosure of Your Information</h3>
                            <p>While safeguarding privacy is paramount, legal mandates might necessitate information disclosure to authorities. Any disclosure of personal data will be communicated directly to the user.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>13. Accessing, Reviewing, and Changing Your Profile</h3>
                            <p>Registered users can review and modify information, with certain restrictions. Users are encouraged to keep their profile information accurate and promptly address any errors.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>14. Control of Your Password </h3>
                            <p>Users are responsible for password confidentiality and account security. Immediate action is required in case of password compromise, and users are liable for activities related to their login credentials.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>15. Security</h3>
                            <p>We prioritize data security, using industry-standard practices to safeguard user data against unauthorized access. Stringent measures are in place to protect personal information.
                            </p>
                        </div>
                        <div className='mb-3'>
                            <h3>16. Severability
                            </h3>
                            <p>Each paragraph of the privacy policy is distinct and independent. Nullification of any paragraph does not affect the remainder unless explicitly stated.
                            </p>
                        </div>
                        <div className='mb-3'>
                            <h3>17. Amendment</h3>
                            <p>The Privacy Policy is subject to periodic updates, with the latest version governing data usage. Users are advised to review the policy regularly.
                            </p>
                        </div>
                        <div className='mb-3'>
                            <h3>18. Automated Decision Making </h3>
                            <p>We do not engage in automatic decision-making or profiling, ensuring transparency and user control over data processing.
                            </p>
                        </div>
                        <div className='mb-3'>
                            <h3>19. Consent Withdrawal, Data Download & Data Removal Requests</h3>
                            <p>Users can withdraw consent, request data download, or request deletion by emailing info@futurefusion.io from their registered email address.
                            </p>
                        </div>
                        <div className='mb-3'>
                            <h3>20. CONTACT US</h3>
                            <p>For further queries or concerns regarding this privacy policy, contact us at info@futurefusion.io. We are committed to addressing your inquiries promptly and ensuring data privacy.
                            </p>
                        </div>
                        <p>This comprehensive Privacy Policy for "Future Fusion" outlines our commitment to data protection, transparency, user rights, and secure information management. For any additional information or assistance, please reach out to our Customer Support Desk. </p>
                    </div>

                </div>


                <Footer />
            </main>
        </>
    )
}
