import React from 'react'
import Header from '../components/Header'
import Category from '../components/Category'
import { Google, NewGameIcon, Success, True } from '../components/AllIcons'
import Card from '../components/Card'
import Footer from '../components/Footer'
import HeaderCard from '../components/HeaderCard'
import { useState } from 'react'
import axios from 'axios'
import { ROOT_URL } from '../utils/rootURL'
import { useNavigate } from 'react-router-dom'
import { getUserData } from '../redux/actions/userDataAction'
import { useDispatch } from 'react-redux'

export default function Login() {
    const [input, setInput] = useState({ email: '', password: '' })
    const [errorMessage, setErrorMessage] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handelInput = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value })
        setErrorMessage('')
    }

    const onLogin = () => {

        const inputData = {
            email: input.email,
            password: input.password,
        };
        axios.post(`${ROOT_URL}/api/login`, inputData).then(res => {
            if (res.data.status === 'success') {
                sessionStorage.setItem('token', res.data.token)
                sessionStorage.setItem('user_id', res.data.user.id)
                dispatch(getUserData())
                navigate('/')
            } else {
                setErrorMessage(res.data.msg)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <>
            <HeaderCard />
            <main className='main'>
                <div className='game_card'>
                    <div className='d-lg-flex align-items-center'>
                        <div className='login_card'>
                            <div className='text-center mb-3 account_logo'>
                                <img src='assets/img/image/login/signin.svg' />
                            </div>
                            <div className='title_card space_mb'>
                                <h2 className='text_dark'>Sign into your account</h2>
                            </div>
                            <div className='sign_list'>
                                <Success />
                                <p className='list_p'>Win Coins on every game play!</p>
                            </div>
                            <div className='sign_list'>
                                <Success />
                                <p className='list_p'>the complete games statistics like Leaderboard, high scores, and rankings.</p>
                            </div>
                            <div className='sign_list'>
                                <Success />
                                <p className='list_p'>Get latest update of launching any new game.</p>
                            </div>
                            <div className='sign_list'>
                                <Success />
                                <p className='list_p'>keep you engrossed for hours</p>
                            </div>
                            <div className='sign_list'>
                                <Success />
                                <p className='list_p'>an extensive variety of free online games </p>
                            </div>
                        </div>
                        <div className='login_card bg_white flex_card position-relative'>
                            {errorMessage &&
                                <p className='note_massage alert'>{errorMessage}</p>
                            }
                            <div className='title_card'>
                                <h2 className='text_dark'>Sign into your account</h2>
                            </div>
                            <div>
                                <div className='login_form'>
                                    <input type='text' className='form-control input_box' name='email' onChange={handelInput} required />
                                    <label className='form-lable'>Email</label>
                                </div>
                                <div className='login_form mt-3'>
                                    <input type='password' className='form-control input_box' name='password' onChange={handelInput} required />
                                    <label className='form-lable'>Password</label>
                                </div>
                                <div className='remember'>
                                    <div className='d-flex align-items-center gap-2'>
                                        <True />
                                        <p className='list_p'>Remember Password</p>
                                    </div>
                                    <a href='forgotpassword' className='page_link list_p'>Forgot Password?</a>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='btn sign_btn' onClick={() => onLogin()}>Sign In</button>
                                <div className='mt-3'>
                                    <a href='#' className='google_link'>
                                        <span>Google</span>
                                        <Google />
                                    </a>
                                </div>
                            </div>
                            <div className='remember justify-content-center gap-1 mt-0'>
                                <p className='list_p'>Don’t have a profile?</p>
                                <a href='signup' className='page_link list_p'>Create New Account</a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </>
    )
}
