import React, { useEffect, useState } from 'react'
import HeaderCard from '../components/HeaderCard'
import Footer from '../components/Footer'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { ROOT_URL } from '../utils/rootURL'
import { Date } from '../components/AllIcons'

export default function Blog() {

    const [isBlog, setIsBlog] = useState([])
    useEffect(() => {
        axios.get(`${ROOT_URL}/blog/blog_list`).then(res => {
            if (res.data.status === 'success') {
                setIsBlog(res.data.data)
            } else {
                console.log(res.data.message)
            }
        }).catch(err => {
            console.log(err, 'errrrreerrrr')
        })
    }, [])

    console.log(isBlog, 'bbb list')

    return (
        <>
            <HeaderCard />
            <main className='main'>
                <div className='allgame_title'>
                    <h2>All Blogs</h2>
                </div>
                <div className='row g-md-4 g-3'>
                    {isBlog.length ?
                        isBlog.map((i, index) =>
                            <div className='col-lg-3 col-md-4' key={index}>
                                <Link to={`/blog/${i.path}`} state={i}>
                                    <div className="card blog_card h-100">
                                        <div className='bg_blog' style={{ backgroundImage: `url(${i.blog_url})` }}></div>                                        
                                        <div className="card-body">                                           
                                            <h5 className="blog_title">{i.blog_name}</h5>
                                            {/* <p className='tex_dark blog_pera'>{i?.description}</p> */}
                                            <Link to='' className='blog_link'>Read More</Link>                                           
                                            <div className='blog_date'>
                                                <Date />
                                                <small className='text-muted'>{i?.ondate}</small>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )
                        :
                        null
                    }
                </div>
                <Footer />
            </main >
        </>
    )
}
