import React from 'react'
import { Facebook, Instagram, Linkedin, Logo, Twitter } from './AllIcons'
import { Link } from 'react-router-dom';

export default function Footer() {

    return (
        <>
            <footer className="footer ">
                <div className="footer_header ">
                    <a href='#' className='game_logo'>
                        <Logo />
                    </a>
                    <div href='#' className="line_g"></div>
                    <div className="game_desc">
                        <p>
                            futurefusion.me is a completely new platform for the online games lovers.
                        </p>
                        <p>
                            The best part is that all games available on our website are free and can be enjoyable on multiple devices, including desktop, mobile phones, tablets, and iPhones or iPads.
                        </p>
                    </div>
                </div>
                <div className="footer_border" />
                <div className="footer_menu_inner">
                    <div className="footer_menu">
                        <div className="menu_header">
                            <p>About futurefusion</p>
                        </div>
                        <div className="menu">
                            <Link to="/">Home</Link>
                            {/* <a href="">Submit a Game</a> */}
                            <Link to="/about">About Us</Link>
                            <Link to="/contact-us">Contact Us</Link>
                            <Link to="/blog">Blog</Link>
                        </div>
                    </div>
                    <div className="footer_menu">
                        <div className="menu_header">
                            <p>Games</p>
                        </div>
                        <div className="menu">
                            <a href="">Sports Games</a>
                            <a href="">Action Games</a>
                            <a href="">Arcade Games</a>
                            <a href="">Racing Games</a>
                            <a href="">Adventure Games</a>
                        </div>
                    </div>
                    <div className="footer_menu">
                        <div className="menu_header">
                            <p>Follow us on</p>
                        </div>
                        <div className="Social_media_icon">
                            <a href="https://www.facebook.com/" target='_blank'>
                                <div className="btn media_icon">
                                    <p>facebook</p>
                                    <Facebook />
                                </div>
                            </a>
                            <a href="https://www.facebook.com/" target='_blank'>
                                <div className="btn media_icon">
                                    <p>Twitter</p>
                                    <Twitter />
                                </div>
                            </a>
                            <a href="https://www.facebook.com/" target='_blank'>
                                <div className="btn media_icon">
                                    <p>instagram</p>
                                    <Instagram />
                                </div>
                            </a>
                            <a href="https://www.facebook.com/" target='_blank'>
                                <div className="btn media_icon">
                                    <p>Linkindia</p>
                                    <Linkedin />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="footer_bottom">
                <div className="footer_bottom_right">
                    <p>Copyright ©&nbsp;2024&nbsp;FUTURE FUSION</p>
                </div>
                <div className="footer_bottom_left">
                    <Link to={'/terms-condition'} className='text_dark' >Terms and Conditions</Link>
                    <div className="right_border" />
                    <Link to={'/privacy-policy'} className='text_dark' >Privacy Policy</Link>
                </div>
            </div>

        </>
    )
}
