import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Favourite, GamePlayed, NewGames, Close, CoinIcon, Coins, CricketIcon, Dashboard, Disliked, FacebookIcon, InstagramIcon, Leaderboard, Liked, LinkedinIcon, Logo, Logout, MenuIcon, Notice, Notification, PlayedGames, Recommended, SearchIcon, TwitterIcon, User, ProfileIcon } from './AllIcons'
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../redux/slice/userData';
import axios from 'axios';
import { ROOT_URL } from '../utils/rootURL';

export default function Header() {

    const [isActive, setIsActive] = useState(false);
    const { data } = useSelector(state => state.userData)
    const dispatch = useDispatch()
    const [search, setSearch] = useState()
    const toggleButtonClass = () => {
        setIsActive(!isActive);
    };

    const navigate = useNavigate()

    const onLogout = () => {
        sessionStorage.clear()
        localStorage.clear()
        dispatch(setUserData({}))
        navigate('/login')
    }

    const [isGame, setIsGame] = useState([])
    const [error, setError] = useState('')
    useEffect(() => {
        if (search?.length > 1) {
            axios.get(`${ROOT_URL}/games/search?name=${search}`).then(res => {
                console.log(res, 'rrreeeesssssss')
                if (res.data.status === 'success') {
                    setIsGame(res.data.data || [])
                } else {
                    setError(res.data.msg)
                }
            }).catch(err => {
                console.log(err)
            })

        } else {
            console.log('err')
        }
    }, [search])

    const clearData = () => {
        setSearch('')
        setIsGame([])
    }

    return (
        <>
            <nav className='navbar p-0'>
                <div className='nav_button'>
                    <button onClick={toggleButtonClass}
                        className="navbar-toggler menu_button"
                        type="button"
                    >
                        <MenuIcon />
                    </button>
                    <Link to={'/'} className='menu_logo'>
                        <Logo />
                    </Link>
                </div>
                <div className='right_side'>


                    <div className='wallets' data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                        <SearchIcon />
                    </div>
                    <div className='wallets notification'>
                        <Notice />
                    </div>
                    {sessionStorage.getItem('token') ?

                        <div className='wallets user_login pointer' onClick={() => onLogout()}>
                            <User />
                            <p>Logout</p>
                        </div>
                        :
                        <Link to='/login'>
                            <div className='wallets user_login'>
                                <User />
                                <p>Login</p>
                            </div>
                        </Link>
                    }
                    <div className='wallets'>
                        <CoinIcon />
                        <p>{data?.coin_balance || 0}</p>
                    </div>
                </div>
                <div className={`mobile_menu ${isActive ? 'menushow' : ''}`}>
                    <div className='top_menu'>
                        <div className='m_header'>
                            <Link to={'/'} className='menu_logo'>
                                <Logo />
                            </Link>
                            <div className='wallets' onClick={toggleButtonClass}>
                                <Close />
                            </div>
                        </div>
                        <div className='renk_menu'>
                            <div className='user_icon'>
                                <img src='assets/img/image/user_icon.svg' />
                            </div>
                            <div className='user_name'>
                                <p>Rechard Michel</p>
                                <div className='renk'>
                                    <p><span>Rank</span> #42553</p>
                                    <p><span>Coins</span>{data?.coin_balance || 0}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='main_menu'>
                        <p className='menu_title'>Dashboard</p>
                        <ul className='menu_list pb-0'>
                            <li>
                                <Link to=''>
                                    <Dashboard />
                                    <span>Dashboard</span>
                                </Link>
                            </li>
                            <li>
                                <Link to=''>
                                    <ProfileIcon />
                                    <span>Profile</span>
                                </Link>
                            </li>
                            <li>
                                <Link to=''>
                                    <Favourite />
                                    <span>Favorite</span>
                                </Link>
                            </li>
                            <li>
                                <Link to=''>
                                    <Coins />
                                    <span>Coins</span>
                                </Link>
                            </li>
                        </ul>
                        <div className='m_hr'></div>
                        <ul className='menu_list'>
                            <li>
                                <Link to=''>
                                    <Notification />
                                    <span>Notification</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={''}>
                                    <Leaderboard />
                                    <span>Leaderboard</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={''}>
                                    <Recommended />
                                    <span>Recommended Games</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={''}>
                                    <GamePlayed />
                                    <span>Total Games Played</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={''}>
                                    <Liked />
                                    <span>Liked Games</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={''}>
                                    <Disliked />
                                    <span>Disliked Games</span>
                                </Link>
                            </li>
                        </ul>
                        <p className='menu_title'>Game Categories</p>
                        <ul className='menu_list'>
                            <li>
                                <Link to={''}>
                                    <NewGames />
                                    <span>New Games</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={''}>
                                    <PlayedGames />
                                    <span>Most Played Games</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={''}>
                                    <CricketIcon />
                                    <span>Cricket Games</span>
                                </Link>
                            </li>
                        </ul>
                        <p className='menu_title'>Contact With Us</p>

                        <div className='social_menu'>
                            <Link to={''}>
                                <FacebookIcon />
                            </Link>
                            <Link to={''}>
                                <TwitterIcon />
                            </Link>
                            <Link to={''}>
                                <InstagramIcon />
                            </Link>
                            <Link to={''}>
                                <LinkedinIcon />
                            </Link>
                        </div>
                        <button type='button' className='logout_btn'>
                            <Logout />
                            <span>Logout</span>
                        </button>
                    </div>
                </div>
            </nav>
            <div className='herade_border'></div>
            <div className="offcanvas gradiunt_card rounded-0 offcanvas-end" tabIndex={-1} id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                <div className="offcanvas-header gap-3">
                    <input type='text' className='form-control input_box border-white text-white' value={search} onChange={(e) => setSearch(e.target.value)} />
                    <button type="button" onClick={clearData} className="btn-close bg-white " data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>
                <div className="offcanvas-body">
                    <div className='row g-3'>
                        {isGame?.length ? isGame.map((i, index) =>
                            <div className='col-lg-6'>
                                <Link to={`/games/${i?.game_name}`} state={i} key={index}>
                                    <div className="new_games_card" style={{ backgroundImage: `url(${i?.Image_url})` }}>
                                        <div className="hexa-match_contant h-auto d-flex justify-content-center align-items-center w-100 p-3 fw-bolder">
                                            <div className="hexa-text">
                                                <p className="m-0"> {i?.game_name}</p>
                                                {/* <div className="player d-flex">
                                        <img src="/assets/img/image/game-controller.svg" alt='game' />
                                        <p className="m-0">9.4M Played</p>
                                    </div> */}
                                            </div>
                                            {/* <div className="play-button d-flex align-items-center ">
                                    <p className="m-0">Play Now</p>
                                    <img src="/assets/img/image/play_controller.svg" alt='game' />
                                </div> */}
                                        </div>
                                    </div>
                                </Link>
                            </div>

                        )
                            :
                            <div className='col-lg-12 text-center'>
                                <h2 className='text-white'>Search more games</h2>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </>
    )
}
