import { createSlice } from "@reduxjs/toolkit";


export const GamesCategorySlice = createSlice({
    name: 'GamesCategorySlice',
    initialState: {
        loading: false,
        data: [],
        error: null,
    },

    reducers: {
        setGamesCategoryLoading: (state, action) => {
            state.loading = true
        },
        setGamesCategoryData: (state, action) => {
            state.loading = false
            state.data = action.payload
        },
        setGamesCategoryError: (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.payload
        }
    }
})

export const { setGamesCategoryLoading, setGamesCategoryData, setGamesCategoryError } = GamesCategorySlice.actions;