import React, { useState } from 'react'
import HeaderCard from '../components/HeaderCard'
import Footer from '../components/Footer'
import { EmailIcon } from '../components/AllIcons'
import axios from 'axios'
import { ROOT_URL } from '../utils/rootURL'

export default function ContactUs() {
    const [input, setInput] = useState({ topic: '', name: '', mobile: '', email: '', probleams: '' })
    const [errorMessage, setErrorMessage] = useState('')

    const handelInput = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value })
        setErrorMessage('')
    }

    const onSubmit = async () => {

        const inputData = {
            topic: input.topic,
            name: input.name,
            mobile: input.mobile,
            email: input.email,
            probleams: input.probleams,
        };
        await axios.post(`${ROOT_URL}/api/contact_us`, inputData).then(res => {
            if (res.data.status === 'success') {
                console.log(res)
                setErrorMessage(res.data)
                setInput({
                    ...input,
                    topic: '', name: '', mobile: '', email: '', probleams: '',
                })
            } else {
                setErrorMessage(res.data.msg)
            }
        }).catch(err => {
            console.log(err)
        })
    }
    return (
        <>
            <HeaderCard />
            <main className='main'>
                <div className='game_card'>
                    <div className='d-lg-flex align-items-center'>
                        <div className='login_card'>
                            <div className='text-center mb-3 account_logo'>
                                <img src='assets/img/image/login/contactus.svg' />
                            </div>
                            <div className='title_card space_mb'>
                                <h2 className='text_dark'>Get in Touch with us</h2>
                            </div>
                            <p className='list_p space_mb'>Have a question? We’d love to hear from you! Drop us a line and we’ll get back to you as soon as possible</p>
                            <div className='contact_id bg_white d-flex align-items-center'>
                                <div className='card_icon d-flex align-items-center justify-content-center'>
                                    <EmailIcon />
                                </div>
                                <div className='title_card flex-column gap-0'>
                                    <h2>Email Us</h2>
                                    <p className='text_gray mb-0'>hello@futurefusion.me</p>
                                </div>
                            </div>
                        </div>
                        <div className='login_card bg_white flex_card card_gap'>
                            <div>
                                {errorMessage &&
                                    <p className={`${errorMessage.status === "success" ? 'success_mgs' : 'error_mgs'}`}>{errorMessage.msg}</p>
                                }
                                <div className='title_card mb-3'>
                                    <h2 className='text_dark'>Contact Us</h2>
                                </div>
                                <p className='list_p'>No worries! Enter your email and we will send you a link to reset your password</p>
                            </div>
                            <form>
                                <div className='login_form'>
                                    <p className='note_massage massage_set'></p>
                                    <select className='input_box form-select' onChange={handelInput} name='topic' required value={input.topic}>
                                        <option value={'General query'}>General query</option>
                                        <option value={'Business Enquiry'}>Business Enquiry</option>
                                        <option value={'Game Submission'}>Game Submission</option>
                                        <option value={'Advertising'}>Advertising</option>
                                    </select>
                                    <label className='form-lable'>Select Topic</label>
                                </div>
                                <div className='login_form mt-3'>
                                    <p className='note_massage massage_set'></p>
                                    <input type='text' className='form-control input_box' onChange={handelInput} name='name' value={input.name} required />
                                    <label className='form-lable'>Name</label>
                                </div>
                                <div className='login_form mt-3'>
                                    <p className='note_massage massage_set'></p>
                                    <input type='nuber' className='form-control input_box' onChange={handelInput} name='mobile' required value={input.mobile} />
                                    <label className='form-lable'>Mobile</label>
                                </div>
                                <div className='login_form mt-3'>
                                    <p className='note_massage massage_set'></p>
                                    <input type='email' className='form-control input_box' onChange={handelInput} name='email' required value={input.email} />
                                    <label className='form-lable'>Email</label>
                                </div>
                                <div className='login_form mt-3'>
                                    <p className='note_massage massage_set'></p>
                                    <textarea className='form-control input_box' rows="1" onChange={handelInput} name='probleams' required value={input.probleams} />
                                    <label className='form-lable'>Enter other issues/problems</label>
                                </div>
                            </form>
                            <button type='button' onClick={onSubmit} className='btn sign_btn'>Send Message</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </>
    )
}
