import React from 'react'
import HeaderCard from '../components/HeaderCard'
import Footer from '../components/Footer'
import SideMenu from '../components/SideMenu'
import OverViewCards from '../components/OverViewCards'
import { CoinIcon, Leaderboard, LeaderboardIcon } from '../components/AllIcons'
import { Link } from 'react-router-dom'

export default function LeaderBoard() {
    return (
        <>
            <HeaderCard />
            <main className='main'>
                <div className='page_layout'>
                    <SideMenu />
                    <div className='w-100'>
                        <OverViewCards />
                        <div className='coin_hitory'>
                            <div className='card_heading card_space mb-0'>
                                <div className='title_card'>
                                    <LeaderboardIcon />
                                    <h2 className='text_dark'>Leaderboard</h2>
                                </div>
                            </div>
                            <div className='leader_history bg_white'>
                                <ul className="nav nav-tabs history_tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="daily-tab" data-bs-toggle="tab" data-bs-target="#daily" type="button" role="tab" aria-controls="daily" aria-selected="true">
                                            Daily
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="weekly-tab" data-bs-toggle="tab" data-bs-target="#weekly" type="button" role="tab" aria-controls="weekly" aria-selected="false">
                                            Weekly
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="monthly-tab" data-bs-toggle="tab" data-bs-target="#monthly" type="button" role="tab" aria-controls="monthly" aria-selected="false">
                                            Monthly
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content mt-0" id="myTabContent">
                                    {/* Tab one */}
                                    <div className="tab-pane fade show active" id="daily" role="tabpanel" aria-labelledby="daily-tab">
                                        <div className='history_row'>
                                            <div className='d-flex align-items-center gap-md-3 gap-2'>
                                                <div className='user_number bg_yellow'>
                                                    <h2>1</h2>
                                                </div>
                                                <div className='user_profile'>
                                                    <img src='assets/img/image/user.svg' className='w-100' alt='user' />
                                                </div>
                                                <div className='user_name'>
                                                    <h3 className='mb-0 text_dark font_700'>Fareed khan</h3>
                                                    <p className='mb-0 text_gray font_700'>@Fareekh</p>
                                                </div>
                                            </div>
                                            <div className='user_name text-end'>
                                                <h3 className='mb-0 text_dark font_700'>1020</h3>
                                                <p className='mb-0 text_gray font_700'>Coins</p>
                                            </div>
                                        </div>
                                        <div className='history_row'>
                                            <div className='d-flex align-items-center gap-md-3 gap-2'>
                                                <div className='user_number bg_gray'>
                                                    <h2>2</h2>
                                                </div>
                                                <div className='user_profile'>
                                                    <img src='assets/img/image/user.svg' className='w-100' alt='user' />
                                                </div>
                                                <div className='user_name'>
                                                    <h3 className='mb-0 text_dark font_700'>Fareed khan</h3>
                                                    <p className='mb-0 text_gray font_700'>@Fareekh</p>
                                                </div>
                                            </div>
                                            <div className='user_name text-end'>
                                                <h3 className='mb-0 text_dark font_700'>1020</h3>
                                                <p className='mb-0 text_gray font_700'>Coins</p>
                                            </div>
                                        </div>
                                        <div className='history_row'>
                                            <div className='d-flex align-items-center gap-md-3 gap-2'>
                                                <div className='user_number bg_orenge'>
                                                    <h2>3</h2>
                                                </div>
                                                <div className='user_profile'>
                                                    <img src='assets/img/image/user.svg' className='w-100' alt='user' />
                                                </div>
                                                <div className='user_name'>
                                                    <h3 className='mb-0 text_dark font_700'>Fareed khan</h3>
                                                    <p className='mb-0 text_gray font_700'>@Fareekh</p>
                                                </div>
                                            </div>
                                            <div className='user_name text-end'>
                                                <h3 className='mb-0 text_dark font_700'>1020</h3>
                                                <p className='mb-0 text_gray font_700'>Coins</p>
                                            </div>
                                        </div>
                                        <div className='history_row'>
                                            <div className='d-flex align-items-center gap-md-3 gap-2'>
                                                <div className='user_number'>
                                                    <h2>4</h2>
                                                </div>
                                                <div className='user_profile'>
                                                    <img src='assets/img/image/user.svg' className='w-100' alt='user' />
                                                </div>
                                                <div className='user_name'>
                                                    <h3 className='mb-0 text_dark font_700'>Fareed khan</h3>
                                                    <p className='mb-0 text_gray font_700'>@Fareekh</p>
                                                </div>
                                            </div>
                                            <div className='user_name text-end'>
                                                <h3 className='mb-0 text_dark font_700'>1020</h3>
                                                <p className='mb-0 text_gray font_700'>Coins</p>
                                            </div>
                                        </div>
                                        <div className='history_row'>
                                            <div className='d-flex align-items-center gap-md-3 gap-2'>
                                                <div className='user_number'>
                                                    <h2>5</h2>
                                                </div>
                                                <div className='user_profile'>
                                                    <img src='assets/img/image/user.svg' className='w-100' alt='user' />
                                                </div>
                                                <div className='user_name'>
                                                    <h3 className='mb-0 text_dark font_700'>Fareed khan</h3>
                                                    <p className='mb-0 text_gray font_700'>@Fareekh</p>
                                                </div>
                                            </div>
                                            <div className='user_name text-end'>
                                                <h3 className='mb-0 text_dark font_700'>1020</h3>
                                                <p className='mb-0 text_gray font_700'>Coins</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Tab Two */}
                                    <div className="tab-pane fade" id="weekly" role="tabpanel" aria-labelledby="weekly-tab">
                                    <div className='history_row'>
                                            <div className='d-flex align-items-center gap-md-3 gap-2'>
                                                <div className='user_number bg_yellow'>
                                                    <h2>1</h2>
                                                </div>
                                                <div className='user_profile'>
                                                    <img src='assets/img/image/user.svg' className='w-100' alt='user' />
                                                </div>
                                                <div className='user_name'>
                                                    <h3 className='mb-0 text_dark font_700'>Fareed khan</h3>
                                                    <p className='mb-0 text_gray font_700'>@Fareekh</p>
                                                </div>
                                            </div>
                                            <div className='user_name text-end'>
                                                <h3 className='mb-0 text_dark font_700'>1020</h3>
                                                <p className='mb-0 text_gray font_700'>Coins</p>
                                            </div>
                                        </div>
                                        <div className='history_row'>
                                            <div className='d-flex align-items-center gap-md-3 gap-2'>
                                                <div className='user_number bg_gray'>
                                                    <h2>2</h2>
                                                </div>
                                                <div className='user_profile'>
                                                    <img src='assets/img/image/user.svg' className='w-100' alt='user' />
                                                </div>
                                                <div className='user_name'>
                                                    <h3 className='mb-0 text_dark font_700'>Fareed khan</h3>
                                                    <p className='mb-0 text_gray font_700'>@Fareekh</p>
                                                </div>
                                            </div>
                                            <div className='user_name text-end'>
                                                <h3 className='mb-0 text_dark font_700'>1020</h3>
                                                <p className='mb-0 text_gray font_700'>Coins</p>
                                            </div>
                                        </div>
                                        <div className='history_row'>
                                            <div className='d-flex align-items-center gap-md-3 gap-2'>
                                                <div className='user_number bg_orenge'>
                                                    <h2>3</h2>
                                                </div>
                                                <div className='user_profile'>
                                                    <img src='assets/img/image/user.svg' className='w-100' alt='user' />
                                                </div>
                                                <div className='user_name'>
                                                    <h3 className='mb-0 text_dark font_700'>Fareed khan</h3>
                                                    <p className='mb-0 text_gray font_700'>@Fareekh</p>
                                                </div>
                                            </div>
                                            <div className='user_name text-end'>
                                                <h3 className='mb-0 text_dark font_700'>1020</h3>
                                                <p className='mb-0 text_gray font_700'>Coins</p>
                                            </div>
                                        </div>
                                        <div className='history_row'>
                                            <div className='d-flex align-items-center gap-md-3 gap-2'>
                                                <div className='user_number'>
                                                    <h2>4</h2>
                                                </div>
                                                <div className='user_profile'>
                                                    <img src='assets/img/image/user.svg' className='w-100' alt='user' />
                                                </div>
                                                <div className='user_name'>
                                                    <h3 className='mb-0 text_dark font_700'>Fareed khan</h3>
                                                    <p className='mb-0 text_gray font_700'>@Fareekh</p>
                                                </div>
                                            </div>
                                            <div className='user_name text-end'>
                                                <h3 className='mb-0 text_dark font_700'>1020</h3>
                                                <p className='mb-0 text_gray font_700'>Coins</p>
                                            </div>
                                        </div>
                                        <div className='history_row'>
                                            <div className='d-flex align-items-center gap-md-3 gap-2'>
                                                <div className='user_number'>
                                                    <h2>5</h2>
                                                </div>
                                                <div className='user_profile'>
                                                    <img src='assets/img/image/user.svg' className='w-100' alt='user' />
                                                </div>
                                                <div className='user_name'>
                                                    <h3 className='mb-0 text_dark font_700'>Fareed khan</h3>
                                                    <p className='mb-0 text_gray font_700'>@Fareekh</p>
                                                </div>
                                            </div>
                                            <div className='user_name text-end'>
                                                <h3 className='mb-0 text_dark font_700'>1020</h3>
                                                <p className='mb-0 text_gray font_700'>Coins</p>
                                            </div>
                                        </div>
                                        <div className='history_row'>
                                            <div className='d-flex align-items-center gap-md-3 gap-2'>
                                                <div className='user_number'>
                                                    <h2>6</h2>
                                                </div>
                                                <div className='user_profile'>
                                                    <img src='assets/img/image/user.svg' className='w-100' alt='user' />
                                                </div>
                                                <div className='user_name'>
                                                    <h3 className='mb-0 text_dark font_700'>Fareed khan</h3>
                                                    <p className='mb-0 text_gray font_700'>@Fareekh</p>
                                                </div>
                                            </div>
                                            <div className='user_name text-end'>
                                                <h3 className='mb-0 text_dark font_700'>1020</h3>
                                                <p className='mb-0 text_gray font_700'>Coins</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Tab Three */}
                                    <div className="tab-pane fade" id="monthly" role="tabpanel" aria-labelledby="monthly-tab">
                                    <div className='history_row'>
                                            <div className='d-flex align-items-center gap-md-3 gap-2'>
                                                <div className='user_number bg_yellow'>
                                                    <h2>1</h2>
                                                </div>
                                                <div className='user_profile'>
                                                    <img src='assets/img/image/user.svg' className='w-100' alt='user' />
                                                </div>
                                                <div className='user_name'>
                                                    <h3 className='mb-0 text_dark font_700'>Fareed khan</h3>
                                                    <p className='mb-0 text_gray font_700'>@Fareekh</p>
                                                </div>
                                            </div>
                                            <div className='user_name text-end'>
                                                <h3 className='mb-0 text_dark font_700'>1020</h3>
                                                <p className='mb-0 text_gray font_700'>Coins</p>
                                            </div>
                                        </div>
                                        <div className='history_row'>
                                            <div className='d-flex align-items-center gap-md-3 gap-2'>
                                                <div className='user_number bg_gray'>
                                                    <h2>2</h2>
                                                </div>
                                                <div className='user_profile'>
                                                    <img src='assets/img/image/user.svg' className='w-100' alt='user' />
                                                </div>
                                                <div className='user_name'>
                                                    <h3 className='mb-0 text_dark font_700'>Fareed khan</h3>
                                                    <p className='mb-0 text_gray font_700'>@Fareekh</p>
                                                </div>
                                            </div>
                                            <div className='user_name text-end'>
                                                <h3 className='mb-0 text_dark font_700'>1020</h3>
                                                <p className='mb-0 text_gray font_700'>Coins</p>
                                            </div>
                                        </div>
                                        <div className='history_row'>
                                            <div className='d-flex align-items-center gap-md-3 gap-2'>
                                                <div className='user_number bg_orenge'>
                                                    <h2>3</h2>
                                                </div>
                                                <div className='user_profile'>
                                                    <img src='assets/img/image/user.svg' className='w-100' alt='user' />
                                                </div>
                                                <div className='user_name'>
                                                    <h3 className='mb-0 text_dark font_700'>Fareed khan</h3>
                                                    <p className='mb-0 text_gray font_700'>@Fareekh</p>
                                                </div>
                                            </div>
                                            <div className='user_name text-end'>
                                                <h3 className='mb-0 text_dark font_700'>1020</h3>
                                                <p className='mb-0 text_gray font_700'>Coins</p>
                                            </div>
                                        </div>
                                        <div className='history_row'>
                                            <div className='d-flex align-items-center gap-md-3 gap-2'>
                                                <div className='user_number'>
                                                    <h2>4</h2>
                                                </div>
                                                <div className='user_profile'>
                                                    <img src='assets/img/image/user.svg' className='w-100' alt='user' />
                                                </div>
                                                <div className='user_name'>
                                                    <h3 className='mb-0 text_dark font_700'>Fareed khan</h3>
                                                    <p className='mb-0 text_gray font_700'>@Fareekh</p>
                                                </div>
                                            </div>
                                            <div className='user_name text-end'>
                                                <h3 className='mb-0 text_dark font_700'>1020</h3>
                                                <p className='mb-0 text_gray font_700'>Coins</p>
                                            </div>
                                        </div>
                                        <div className='history_row'>
                                            <div className='d-flex align-items-center gap-md-3 gap-2'>
                                                <div className='user_number'>
                                                    <h2>5</h2>
                                                </div>
                                                <div className='user_profile'>
                                                    <img src='assets/img/image/user.svg' className='w-100' alt='user' />
                                                </div>
                                                <div className='user_name'>
                                                    <h3 className='mb-0 text_dark font_700'>Fareed khan</h3>
                                                    <p className='mb-0 text_gray font_700'>@Fareekh</p>
                                                </div>
                                            </div>
                                            <div className='user_name text-end'>
                                                <h3 className='mb-0 text_dark font_700'>1020</h3>
                                                <p className='mb-0 text_gray font_700'>Coins</p>
                                            </div>
                                        </div>
                                        <div className='history_row'>
                                            <div className='d-flex align-items-center gap-md-3 gap-2'>
                                                <div className='user_number'>
                                                    <h2>6</h2>
                                                </div>
                                                <div className='user_profile'>
                                                    <img src='assets/img/image/user.svg' className='w-100' alt='user' />
                                                </div>
                                                <div className='user_name'>
                                                    <h3 className='mb-0 text_dark font_700'>Fareed khan</h3>
                                                    <p className='mb-0 text_gray font_700'>@Fareekh</p>
                                                </div>
                                            </div>
                                            <div className='user_name text-end'>
                                                <h3 className='mb-0 text_dark font_700'>1020</h3>
                                                <p className='mb-0 text_gray font_700'>Coins</p>
                                            </div>
                                        </div>
                                        <div className='history_row'>
                                            <div className='d-flex align-items-center gap-md-3 gap-2'>
                                                <div className='user_number'>
                                                    <h2>7</h2>
                                                </div>
                                                <div className='user_profile'>
                                                    <img src='assets/img/image/user.svg' className='w-100' alt='user' />
                                                </div>
                                                <div className='user_name'>
                                                    <h3 className='mb-0 text_dark font_700'>Fareed khan</h3>
                                                    <p className='mb-0 text_gray font_700'>@Fareekh</p>
                                                </div>
                                            </div>
                                            <div className='user_name text-end'>
                                                <h3 className='mb-0 text_dark font_700'>1020</h3>
                                                <p className='mb-0 text_gray font_700'>Coins</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </>
    )
}
