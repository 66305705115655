import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

export default function Authe() {
    if (!sessionStorage.getItem('token')) {
        return <Outlet />;
    } else {
        return <Navigate to={'/'} />
        // return <Outlet />;
    }
}
