import React from 'react'
import { Dashboard, Disliked, Favourite, GamePlayed, Leaderboard, Liked, Logout, ProfileIcon, Recommended, } from './AllIcons'
import { Link } from 'react-router-dom'

export default function SideMenu() {
    return (
        <>
            <div className='side-menu gradiunt_card'>
                <div className='user_details text-center'>
                    <div className='profile_icon text-center'>
                        <img src='assets/img/image/user_icon.svg' alt='user' />
                    </div>
                    <h2 className='card_title mb-0 mt-3'>Welcome Back!</h2>
                    <p className='text_white mb-0'>Rechard Michel</p>
                </div>
                <div className='nav_menu'>
                    <Link to='' className='nav_link'>
                        <Dashboard />
                        <span>Dashboard</span>
                    </Link>
                    <div className='menu_border'></div>
                    <Link to='' className='nav_link'>
                        <ProfileIcon />
                        <span>Profile</span>
                    </Link>
                    <div className='menu_border'></div>
                    <Link to='' className='nav_link'>
                        <GamePlayed />
                        <span>Total Games Played</span>
                    </Link>
                    <div className='menu_border'></div>
                    <Link to='' className='nav_link'>
                        <Recommended />
                        <span>Recommended Games</span>
                    </Link>
                    <div className='menu_border'></div>
                    <Link to='' className='nav_link'>
                        <Favourite />
                        <span>Favorite</span>
                    </Link>
                    <div className='menu_border'></div>
                    <Link to='' className='nav_link'>
                        <Liked />
                        <span>Liked Games</span>
                    </Link>
                    <div className='menu_border'></div>
                    <Link to='' className='nav_link'>
                        <Disliked />
                        <span>Disliked Games</span>
                    </Link>
                    <div className='menu_border'></div>
                    <Link to='' className='nav_link'>
                        <Leaderboard />
                        <span>Leaderboard</span>
                    </Link>
                    <button className='menu_btn'>
                        <Logout />
                        <span>Logout</span>
                    </button>
                </div>

            </div>
        </>
    )
}
