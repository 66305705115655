import React, { useEffect } from 'react'
import { SwiperSlide, Swiper } from "swiper/react";
import { FreeMode } from "swiper/modules";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import CategoryCard from './CategoryCard';
import { useDispatch, useSelector } from 'react-redux';
import { getGamesCategory } from '../redux/actions/GamesCategoryAction';


export default function Category() {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getGamesCategory(''))
    }, [])

    const { data: category } = useSelector(state => state.GamesCategory)

    return (
        <div>
            <Swiper
                slidesPerView={10.5}
                spaceBetween={10}
                freeMode={true}
                modules={[FreeMode]}
                breakpoints={{
                    320: {
                        slidesPerView: 3.1,
                        spaceBetween: 5,
                    },
                    360: {
                        slidesPerView: 3.4,
                        spaceBetween: 5,
                    },
                    400: {
                        slidesPerView: 3.8,

                    },
                    490: {
                        slidesPerView: 4.4,
                        spaceBetween: 5,

                    },
                    600: {
                        slidesPerView: 5.2,
                        spaceBetween: 5,
                    },
                    768: {
                        slidesPerView: 5.4,
                        spaceBetween: 10,
                    },
                    840: {
                        slidesPerView: 6,
                    },
                    1024: {
                        slidesPerView: 6.3,
                    },
                    1200: {
                        slidesPerView: 6.6,
                    },
                    1366: {
                        slidesPerView: 7.5,
                    },
                    1440: {
                        slidesPerView: 7.9,
                    },
                    1600: {
                        slidesPerView: 8.6,
                    },
                    1700: {
                        slidesPerView: 9.6,
                    },
                    1880: {
                        slidesPerView: 10.5,
                    },

                }}
                className="mySwiper"
            >
                {category?.map((i, index) =>
                    <SwiperSlide key={index}>
                        <CategoryCard data={i} />
                    </SwiperSlide>
                )}

            </Swiper>

        </div>
    )
}
