import axios from "axios"
import { setGamesCategoryData, setGamesCategoryError, setGamesCategoryLoading } from "../slice/GamesCategorySlice"
import { ROOT_URL } from "../../utils/rootURL"

export const getGamesCategory = (category) => {
    return dispatch => {
        dispatch(setGamesCategoryLoading(true))
        axios.get(`${ROOT_URL}/games/catagory${category}`).then(res => {
            dispatch(setGamesCategoryData(res.data.data || []))
        }).catch(err => {
            dispatch(setGamesCategoryError(err))
        })
    }
}