import React from 'react'
import HeaderCard from '../components/HeaderCard'
import Footer from '../components/Footer'
import { Link, useLocation } from 'react-router-dom'
import { Date } from '../components/AllIcons'

export default function BlogDetails() {
    const { state } = useLocation()
    return (
        <>
            <HeaderCard />
            <main className='main'>
                <div className='allgame_title'>
                    <h2>Blog Details</h2>
                </div>
                <div className='row'>
                    <div className='col-lg-12' >                       
                        <div className='bloge_details'>
                            <div className='bg_blog details_bg' style={{ backgroundImage: `url(${state?.blog_url})` }}>
                                <div className='blog_date'>
                                    <Date />
                                    <small className='text-muted'>{state?.ondate}</small>
                                </div>
                            </div>
                            <h2 className='text_dark'>{state.blog_name}</h2>
                            <p className='text_dark mb-2'>{state.description}</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </main >
        </>
    )
}
