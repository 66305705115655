import { createSlice } from "@reduxjs/toolkit";


export const AllGamesSlice = createSlice({
    name: 'AllGamesSlice',
    initialState: {
        loading: false,
        data: [],
        error: null,
    },

    reducers: {
        setAllGamesLoading: (state, action) => {
            state.loading = true
        },
        setAllGamesData: (state, action) => {
            state.loading = false
            state.data = action.payload
        },
        setAllGamesError: (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.payload
        }
    }
})

export const { setAllGamesLoading, setAllGamesData, setAllGamesError } = AllGamesSlice.actions;