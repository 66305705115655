import React, { useEffect, useState } from 'react'
import { SwiperSlide, Swiper } from "swiper/react";
import { FreeMode } from "swiper/modules";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getGamesCategory } from '../redux/actions/GamesCategoryAction';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ROOT_URL } from '../utils/rootURL';
import Loader from './Loader';

export default function TopGames({ category }) {
    const [topGame, setTopGame] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)

        axios.get(`${ROOT_URL}/games/catagory${category}`).then(res => {
            if (res.status === 200) {
                setTopGame(res.data.data)
                setIsLoading(false)

            }
        }
        ).catch(err => {
            console.log(err, 'error')
            setIsLoading(false)

        }
        )
    }, [])

    // const dispatch = useDispatch()

    // useEffect(() => {
    //     dispatch(getGamesCategory(category))
    // }, [category])

    // const { data: topgame } = useSelector(state => state.GamesCategory)

    return (
        <>
            {isLoading && <Loader className={'text-white'} />}
            <Swiper
                slidesPerView={6}
                spaceBetween={10}
                freeMode={true}
                modules={[FreeMode]}
                breakpoints={{
                    320: {
                        slidesPerView: 2,
                    },
                    640: {
                        slidesPerView: 3.3,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 2.5,
                    },
                    1024: {
                        slidesPerView: 2.5,
                    },
                    1280: {
                        slidesPerView: 3,
                    },
                    1440: {
                        slidesPerView: 3.5,
                    },
                    1640: {
                        slidesPerView: 4,
                    },
                }}
                className="mySwiper">
                {topGame.length ?
                    topGame.map((i, index) =>
                        <SwiperSlide key={index}>
                            <Link to={`/games/${i?.game_name}`} state={i}>
                                <div className="new_games_card" style={{ backgroundImage: `url(${i?.Image_url})` }}>
                                    <div className="hexa-match_contant d-flex justify-content-between w-100 p-3 fw-bolder">
                                        <div className="hexa-text ">
                                            <p className="m-0"> {i?.game_name}</p>
                                            <div className="player d-flex">
                                                <img src="/assets/img/image/game-controller.svg" alt='game' />
                                                <p className="m-0">9.4M Played</p>
                                            </div>
                                        </div>
                                        <div className="play-button d-flex align-items-center ">
                                            <p className="m-0">Play Now</p>
                                            <img src="/assets/img/image/play_controller.svg" alt='game' />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </SwiperSlide>
                    )
                    :
                    <h3 className='data_not_found'>Games Not Found</h3>

                }


            </Swiper>
        </>
    )
}
