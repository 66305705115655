import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';

export default function GameReport({ show, setShow }) {

    const [isActive, setIsActive] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Modal show={show} className='game_report' onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title className='card_title'>Report Game</Modal.Title>
                    <p className='mb-0 text_white text-center'>What kind of problem have you encountered?</p>
                </Modal.Header>
                <Modal.Body className='g_report_body'>
                    <div className=''>
                        <form>
                            <div className="form-check report_check">
                                <input className="form-check-input" type="radio" name="" />
                                <label className="form-check-label">
                                    It’s not starting
                                </label>
                            </div>
                            <div className="form-check report_check">
                                <input className="form-check-input" type="radio" name="" defaultChecked="" />
                                <label className="form-check-label">
                                    It stopped working
                                </label>
                            </div>
                            <div className="form-check report_check">
                                <input className="form-check-input" type="radio" name="" defaultChecked="" />
                                <label className="form-check-label">
                                    Delayed loading
                                </label>
                            </div>
                            <div className="form-check report_check">
                                <input className="form-check-input" type="radio" name="" defaultChecked="" />
                                <label className="form-check-label">
                                    Game not responding
                                </label>
                            </div>
                            <div className="form-check report_check">
                                <input className="form-check-input" type="radio" name="" defaultChecked="" />
                                <label className="form-check-label">
                                    Instructions not clear
                                </label>
                            </div>
                            <div className="form-check report_check">
                                <input className="form-check-input" type="radio" name="" defaultChecked="" onClick={() => setIsActive(!isActive)} />
                                <label className="form-check-label">
                                    Other
                                </label>
                            </div>
                            <div className={`login_form d-none ${isActive ? 'show-report' : ''}`}>
                                <textarea className='form-control input_box' rows="1" name='' required />
                                <label className='form-lable'>Enter other issues/problems</label>
                            </div>
                            <button type='button' className='btn sign_btn btn_space'>Send Feedback</button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
