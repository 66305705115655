import React from 'react'
import { Link } from 'react-router-dom'

export default function CategoryCard({ data }) {
    return (
        <>
            <Link to={`/online-games/${data.Categoryname.toLowerCase()}`} state={data}>
                <div className="sport_card d-flex flex-column justify-content-center align-items-center">
                    <img src="/assets/img/image/line1.png" alt="category" />
                    <img className="sport_image" src={data.icon} alt="category" />
                    <p className="m-0">{data.Categoryname}</p>
                </div>
            </Link>
        </>
    )
}
