import React from 'react'
import { Link } from 'react-router-dom'

export default function Card({ className, icon, title, titleClass, btnName, btnClass, link, children }) {
    return (
        <>
            <div className={`game_card ${className}`}>
                <div className='card_heading'>
                    <div className='title_card'>
                        {icon && icon}
                        <h2 className={titleClass}>{title}</h2>
                    </div>
                    {btnName &&
                        <Link to={link} className={btnClass}>{btnName}</Link>
                    }
                </div>
                {children}
            </div>
        </>
    )
}
