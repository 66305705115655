import React, { useEffect, useState } from 'react'
import { Coins, GamePlayed, Liked, Rank, Users } from './AllIcons'
import axios from 'axios'
import { ROOT_URL } from '../utils/rootURL'

export default function OverViewCards() {
    const [isDashboard, setIsDashboard] = useState([])

    useEffect(() => {
        axios.get(`${ROOT_URL}/api/dashboard`, {
            headers: {
                Authorization: `Berear ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            if (res.data.status === "Success") {
                setIsDashboard(res.data.data || [])
            } else {
                setIsDashboard([])
            }
        }).catch(err => {
            console.log(err, 'errorororroro')
        })
    }, [])

    return (
        <>
            <div className='over_view'>
                <div className='overall_card'>
                    <div className='card_icon d-flex align-items-center justify-content-center'>
                        <Rank />
                    </div>
                    <div className='title_card flex-column gap-0'>
                        <h2 className='font_700'>{isDashboard[0]?.overall_rank}</h2>
                        <p className='mb-0 text_gray'>Overall Rank</p>
                    </div>
                </div>
                {/* <div className='overall_card'>
                    <div className='card_icon d-flex align-items-center justify-content-center'>
                        <Users />
                    </div>
                    <div className='title_card flex-column gap-0'>
                        <h2 className='font_700'>186</h2>
                        <p className='mb-0 text_gray'>Friends</p>
                    </div>
                </div> */}
                <div className='overall_card'>
                    <div className='card_icon d-flex align-items-center justify-content-center'>
                        <Liked />
                    </div>
                    <div className='title_card flex-column gap-0'>
                        <h2 className='font_700'>{isDashboard[0]?.like_game}</h2>
                        <p className='mb-0 text_gray'>Liked Games</p>
                    </div>
                </div>
                <div className='overall_card'>
                    <div className='card_icon d-flex align-items-center justify-content-center'>
                        <GamePlayed />
                    </div>
                    <div className='title_card flex-column gap-0'>
                        <h2 className='font_700'>{isDashboard[0]?.game_played}</h2>
                        <p className='mb-0 text_gray'>Games Played</p>
                    </div>
                </div>
                <div className='overall_card'>
                    <div className='card_icon d-flex align-items-center justify-content-center'>
                        <Coins />
                    </div>
                    <div className='title_card flex-column gap-0'>
                        <h2 className='font_700'>{isDashboard[1]?.coin_balance}</h2>
                        <p className='mb-0 text_gray'>Coins</p>
                    </div>
                </div>
            </div>
        </>
    )
}
