import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Category from '../components/Category'
import HeaderCard from '../components/HeaderCard'

export default function About() {
    return (
        <>
           <HeaderCard/>
            <main className='main'>
                <div className='container'>
                    <div className='policy'>
                        <h1>Overview about Future Fusion</h1>
                        <p>Future Fusion is a leading online platform dedicated to providing a diverse range of free games for players of all ages. With a user-friendly interface and a commitment to innovation, Future Fusion offers a seamless gaming experience across multiple devices.
                        </p>
                        <p>Whether you're into action-packed adventures, mind-bending puzzles, or thrilling racing games, Future Fusion has something for everyone. Our extensive collection of games, including both in-house developed titles and popular favorites, ensures endless entertainment for gamers worldwide.
                        </p>

                        <div className='mb-3'>
                            <h3>A Widely Accepted Platform for Fun Seekers
                            </h3>
                            <p>Future Fusion prides itself on being a widely accepted destination for fun seekers of all kinds. Our platform is designed to cater to the diverse tastes and preferences of gamers, offering a wide variety of games that can be enjoyed by players of any age or background. With a focus on accessibility and inclusivity, Future Fusion provides a welcoming environment where every player can find something they love and enjoy hours of entertainment.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>Our Mission </h3>
                            <p>At Future Fusion, our mission is to provide a vast collection of free online games that cater to the needs and preferences of players worldwide. We are committed to fostering a sense of community and collaboration among gamers, while also supporting the work of talented developers. Our mission is to create an environment where everyone feels valued and included, and where gaming is a source of joy and excitement for all.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>Our Vision</h3>
                            <p>Our vision at Future Fusion is to become the premier destination for free online gaming, known and trusted by players around the globe. We aspire to continuously innovate and improve our platform, offering new and exciting experiences that captivate and delight gamers of all ages. With a focus on accessibility, inclusivity, and quality, we aim to create a world where gaming brings people together, fosters creativity, and sparks joy in the hearts of millions.</p>
                        </div>
                        <div className='mb-3'>
                            <p>In conclusion, Future Fusion stands as a beacon of accessible and inclusive gaming, offering a diverse array of free online games for players worldwide. With a commitment to innovation and community, we strive to make gaming a source of joy and entertainment for all.</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </>
    )
}
