import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Category from '../components/Category'
import HeaderCard from '../components/HeaderCard'

export default function TermsCondition() {
    return (
        <>
           <HeaderCard/>
            <main className='main'>
                <div className='container'>
                    <div className='policy'>
                        <h1>TERMS AND CONDITIONS FOR "Future Fusion"</h1>
                        <p>Effective as of April 16, 2024</p>
                        <div className='mb-3'>
                            <h3>1. GENERAL TERMS AND CONDITIONS</h3>
                            <h5>1.1 Changes to the Terms</h5>
                            <p>"Future Fusion" reserves the right to modify these Terms of Use at any time without prior notice. Users are responsible for regularly reviewing the Terms to stay informed of any updates. Their continued use of the Website following the posting of changes constitutes acceptance of those changes.</p>
                            <h5>1.2 Additional Terms</h5>
                            <p>Certain sections of the website may have additional usage guidelines and rules that are to be considered supplementary to these terms. Users agree to comply with these additional guidelines and rules when using the related services on the Website.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>2. ELECTRONIC-DELIVERY POLICY AND YOUR CONSENT</h3>
                            <p>By using the "Future Fusion" website, users agree to receive required notices, agreements, and other information electronically. If users no longer wish to receive such notices electronically, it is recommended to discontinue the use of the Website.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>3. SERVICES AVAILABILITY</h3>
                            <p>While "Future Fusion" aims to provide a stable and reliable website, users acknowledge that games and services may be unavailable due to system maintenance or technical issues. The company reserves the right to suspend or permanently remove any game or service without prior notice, and it will not be liable for any resulting loss, damage, or inconvenience to users.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>4. PRIVACY POLICY</h3>
                            <p>The Privacy Policy outlines how "Future Fusion" manages personal data provided by users and ensures transparency in data practices. Users are encouraged to review the Privacy Policy for detailed insights into the use of their information.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>5. ACCESS COSTS</h3>
                            <p>Users are responsible for their own equipment and internet connections required to access and use the "Future Fusion" website. Fees associated with accessing the Website through wireless applications or other communication services are the sole responsibility of the users.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>6. USER RESPONSIBILITIES</h3>
                            <h5>6.1 Use of the Website</h5>
                            <p>Users agree to use the website for lawful purposes only and to keep their contact information up-to-date.</p>
                            <h5>6.2 Restrictions of Use</h5>
                            <p>Users are prohibited from using the Website for commercial gain, unauthorized advertising, or providing false personal information. Any conduct that damages the Website's servers, violates intellectual property rights, or interrupts the use and enjoyment of the Website by others is strictly prohibited.</p>
                            <h5>6.3 Submissions on the Website</h5>
                            <p>Users may not submit or transmit content that violates the rights of others, is unlawful, abusive, deceptive, fraudulent, invasive of privacy, or contains viruses. Any user-submitted content covered by intellectual property rights shall be licensed to "Future Fusion" under specific terms.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>7. USER CONDUCT</h3>
                            <p>Users may be banned, accounts suspended, or terminated across the "Future Fusion" network if inappropriate language or behavior is used. Users are personally responsible for their content, postings, and communications and agree not to engage in activities that violate the Terms and Conditions.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>8. AGE RESTRICTIONS</h3>
                            <p>The "Future Fusion" website and services are not intended for individuals under 13 years old. By accessing the services, users represent and warrant that they are 13 years of age or older. Parents or guardians are responsible for determining the website's appropriateness for their children.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>9. UPLOADING GAMES</h3>
                            <p>Upon uploading a file, users warrant that they own the copyright or have the necessary rights for it. Uploading games is for non-commercial purposes only, and users must adhere to specified rules when submitting content.
                            </p>
                        </div>
                        <div className='mb-3'>
                            <h3>10. VIRUSES</h3>
                            <p>Users are responsible for ensuring their devices have suitable anti-virus software. "Future Fusion" will take reasonable steps to ensure that the website's content is free from viruses and other harmful software.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>11. THIRD-PARTY WEBSITES </h3>
                            <p>The "Future Fusion" website may include links to other websites or resources. The company is not responsible for the availability or content of such external sites. disclaims endorsement and responsibility for any materials available on those websites or resources.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>12. DISCLAIMER REGARDING THIRD-PARTY GAMES</h3>
                            <p>Users acknowledge and agree that any third-party games accessed via "Future Fusion" are provided by third-party providers and are governed by their individual terms of use, license agreements, and privacy policies.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>13. INTELLECTUAL PROPERTY</h3>
                            <p>Users acknowledge that all content on the Website, including intellectual property rights such as trademarks and copyrights, is protected.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>14. CONTACT US</h3>
                            <p>For further queries or concerns regarding this T&C, contact us at info@futurefusion.io. We are committed to addressing your inquiries promptly and ensuring data privacy.</p>
                        </div>
                        <div className='mb-3'>
                            <h3>15. Response Time</h3>
                            <p>"Future Fusion" aims to respond to user queries in a timely manner, with efforts made to address concerns promptly.</p>
                        </div>

                    </div>

                </div>


                <Footer />
            </main>
        </>
    )
}
