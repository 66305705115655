import { createSlice } from "@reduxjs/toolkit";

export const userDataSlice = createSlice({
    name: 'userDataSlice',
    initialState: {
        loading: false,
        data: {},
        error: null,
    },

    reducers: {
        setUserDataLoading: (state, action) => {
            state.loading = true
        },
        setUserData: (state, action) => {
            state.loading = false
            state.data = action.payload
        },
        setUserDataError: (state, action) => {
            state.loading = false
            state.data = []
            state.error = action.payload
        }
    }
})

export const { setUserDataLoading, setUserData, setUserDataError } = userDataSlice.actions;