import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

export default function AutheLogin() {
    if (sessionStorage.getItem('token')) {
        return <Outlet />;
    } else {
        return <Navigate to={'/'} />
        // return <Outlet />;
    }
}
