import { combineReducers } from "redux";
import { AllGamesSlice } from "../slice/AllGamesSlice";
import { GamesCategorySlice } from "../slice/GamesCategorySlice";
import { userDataSlice } from "../slice/userData";


export const rootReducer = combineReducers({
    AllGames: AllGamesSlice.reducer,
    GamesCategory: GamesCategorySlice.reducer,
    userData: userDataSlice.reducer,
})