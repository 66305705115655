import React from 'react'
import HeaderCard from '../components/HeaderCard'
import SideMenu from '../components/SideMenu'
import Footer from '../components/Footer'
import OverViewCards from '../components/OverViewCards'
import { UserInfo, UserProfile } from '../components/AllIcons'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'


export default function Profile() {

    const { data } = useSelector(state => state.userData)

    return (
        <>
            <HeaderCard />
            <main className='main'>
                <div className='page_layout'>
                    <SideMenu />
                    <div className='w-100'>
                        <OverViewCards />
                        <div className='game_card'>
                            <div className='row'>
                                <div className='col-xxl-6 col-xl-8'>
                                    <div className='game_card mb-0 bg_white'>
                                        <div className='tab_gradient profile_card'>
                                            <div className='title_card'>
                                                <UserProfile />
                                                <h2 className='text_white'>My Profile</h2>
                                            </div>
                                            <div className='d-flex align-items-center gap-3'>
                                                <div className='profile_icon'>
                                                    <img src='assets/img/image/user_icon.svg' alt='user' />
                                                </div>
                                                <div className='user_title'>
                                                    <h3 className='font_700 text_white'>{data.username}</h3>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <p><span className='text_gray'>Rank</span> #42553</p>
                                                        <p><span className='text_gray'>Coins</span> 1500</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='user_info'>
                                            <div className='title_card'>
                                                <UserInfo />
                                                <h2>Personal Information</h2>
                                            </div>
                                            <div className='d-flex flex-column gap-sm-4 gap-3'>
                                                <div className='user_data'>
                                                    <p>Name</p>
                                                    <span>:</span>
                                                    <p>{data.fname || '-'} {data.lname || '-'}</p>
                                                </div>
                                                <div className='user_data'>
                                                    <p>Gender</p>
                                                    <span>:</span>
                                                    <p>{data.gender || '-'}</p>
                                                </div>
                                                <div className='user_data'>
                                                    <p>Email ID</p>
                                                    <span>:</span>
                                                    <p>{data.email || '-'}</p>
                                                </div>
                                                <div className='user_data'>
                                                    <p>First Name</p>
                                                    <span>:</span>
                                                    <p>{data.fname || '-'}</p>
                                                </div>
                                                <div className='user_data'>
                                                    <p>Last Name</p>
                                                    <span>:</span>
                                                    <p>{data.lname || '-'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <Link to='/edit-profile' state={data} className='profile_btn d-block w-100'>
                                            Update Profile
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </>
    )
}
