import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import { Coins, Disliked, Favourite, FullScreen, GamePlayed, GameRemoteIcon, HalfStar, Liked, Not, PlayedGames, Share, Star, ThreeDots } from '../components/AllIcons'
import { Link, useLocation } from 'react-router-dom'
import HeaderCard from '../components/HeaderCard'
import Card from '../components/Card'
import MostGames from '../components/MostGames'
import Category from '../components/Category'
import PlayGame from '../components/PlayGame'
import GameReport from '../components/GameReport'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { ROOT_URL } from '../utils/rootURL'

export default function GamePlay() {

  const [isToggle, setIsToggle] = useState(false)
  const [isActive, setIsActive] = useState(false);
  const [show, setShow] = useState(false);
  const { data } = useSelector(state => state.userData)
  const [gameStatus, setGameStatus] = useState({})

  const toggleButtonClass = () => {
    setIsActive(!isActive);
  };
  const { state } = useLocation()

  const [isMore, setIsMore] = useState(false);

  const getGameStatus = async () => {
    if (!(state?.game_name && data?.id)) return
    try {
      const res = await axios.get(
        `${ROOT_URL}/games/${state?.game_name || ''}/${data?.id || ''}`,
        {
          headers: {
            Authorization: `Berear ${sessionStorage.getItem('token')}`
          }
        }
      )
      setGameStatus(res.data?.data?.[0] || {})
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getGameStatus()
  }, [state, data])

  const handleLike = async (type = 'LIKE') => {
    if (!(state?.game_name && data?.id)) return
    try {
      const res = await axios.post(
        `${ROOT_URL}/games/${state.game_name}/${type}/${data.id}`,
        null,
        {
          headers: {
            Authorization: `Berear ${sessionStorage.getItem('token')}`
          }
        }
      )
      getGameStatus()
    } catch (err) {
      console.log(err)
    }
  }

  const handleFavourite = async () => {
    if (!(state?.game_name && data?.id)) return
    try {
      const res = await axios.post(
        `${ROOT_URL}/games/${state.game_name}/FAVOURITE/${data.id}`,
        null,
        {
          headers: {
            Authorization: `Berear ${sessionStorage.getItem('token')}`
          }
        }
      )
      getGameStatus()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <HeaderCard />
      <main className='main'>
        {isToggle ?
          <div className='position-relative'>
            <iframe src={`${state?.Game_url}?id=${sessionStorage.getItem('user_id') || ''}`} height={810} allowFullScreen={true} className='' width={'100%'}>

            </iframe>
            {/* <div className='play_game preview_game' style={{ backgroundImage: `url(${state?.Image_url})` }}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <Link to={''} className="d-flex gap-2 played"><GameRemoteIcon /> 9.4M Played</Link>
                                <Link to={''} className="d-flex gap-2 played"><Coins /> 20 Coins</Link>
                            </div>
                            <div className='match_card'>
                                <div className='match_title'>
                                    <img src='/assets/img/image/matchcard.webp' alt="Match Card" />
                                    <div className='title_card '>
                                        <h2 className='text_dark'>{state?.game_name}</h2>
                                    </div>
                                </div>
                                <div className='screen_card'>
                                    <div className='tab_card'>
                                        <Liked />
                                        <span>like</span>
                                    </div>
                                    <div className='tab_card'>
                                        <Disliked />
                                        <span>Dislike</span>
                                    </div>
                                    <div className='tab_card'>
                                        <Favourite />
                                        <span className='text_dark'>Favourite</span>
                                    </div>
                                    <div className='tab_card tab_gradient'>
                                        <Share />
                                        <span className='text-white'>Share</span>
                                    </div>
                                    <div className='tab_card'>
                                        <FullScreen />
                                        <span className='text_dark'>Screen</span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
          </div>
          :

          <div className='play_game' style={{ backgroundImage: `url(${state?.Image_url})` }}>
            <div className='d-flex align-items-center justify-content-between'>
              <Link to={''} className="d-flex gap-2 align-items-center played"><GamePlayed /> 9.4M Played</Link>
              <Link to={''} className="d-flex gap-2 align-items-center played"><Coins /> {data?.coin_balance || 0} Coins</Link>
            </div>

            {/* <iframe src={state?.Game_url} height={650} width={'100%'} /> */}

            <div className='play_btn'>
              <button className='button_play' onClick={() => setIsToggle(!isToggle)}>
                <span>Play Now</span>
                <img src="/assets/img/image/play_controller.svg" alt='game' />
              </button>
            </div>

            <div className='match_card'>
              <div className='match_title'>
                <img src={state?.Image_url} width={50} alt="Match Card" />
                <div className='title_card '>
                  <h2 className='text_dark'>{state?.game_name}</h2>
                </div>
              </div>
              <div className='screen_card'>
                <div className='tab_card' onClick={handleLike}>
                  <Liked fill={gameStatus.islike === true} />
                  <span className='text_dark'>Like</span>
                </div>
                <div className='tab_card' onClick={() => handleLike('DISLIKE')}>
                  <Disliked fill={gameStatus.islike === false} />
                  <span className='text_dark'>Dislike</span>
                </div>
                <div className='tab_card more_card' onClick={() => setIsMore(!isMore)}>
                  <ThreeDots />
                  <span className='text_dark'>More</span>
                </div>
                <div className={`screen_card option_card ${isMore ? 'more-option' : ''}`}>
                  <div className='tab_card' onClick={handleFavourite}>
                    <Favourite fill={gameStatus.isfav} />
                    <span className='text_dark'>Favourite</span>
                  </div>
                  <div className='tab_card tab_gradient'>
                    <Share />
                    <span className='text-white'>Share</span>
                  </div>
                  <div className='tab_card'>
                    <FullScreen />
                    <span className='text_dark'>Screen</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        }
        <Card title={'Most Played Games'} className={''} titleClass={'text_dark'} btnName={'View All'} btnClass={'view_link view_text'}  >
          <MostGames />
        </Card>

        <div className='online_game game_space'>
          <div className='d-flex flex-wrap gap-3 justify-content-between align-items-center'>
            <div className="nav nav-tabs match_tabs" id="nav-tab" role="tablist">
              <button className="nav-link active" id="nav-about-tab" data-bs-toggle="tab" data-bs-target="#nav-about" type="button" role="tab" aria-controls="nav-about" aria-selected="true">
                About
              </button>
              <button className="nav-link" id="nav-walkthrough-tab" data-bs-toggle="tab" data-bs-target="#nav-walkthrough" type="button" role="tab" aria-controls="nav-walkthrough" aria-selected="false">
                Walkthrough
              </button>
              <button className="nav-link" id="nav-controls-tab" data-bs-toggle="tab" data-bs-target="#nav-controls" type="button" role="tab" aria-controls="nav-controls" aria-selected="false">
                Controls
              </button>
            </div>
            <div className='d-flex gap-1 align-items-center'>
              <div className='review_card'>
                <div className='review_star'>
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                  <HalfStar />
                </div>
                <span>4.1(14)</span>
              </div>
              <div className='notice_box' onClick={() => setShow(true)}>
                <Not />
              </div>
              {/* onClick={handleShow} */}
            </div>
          </div>

          <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-about" role="tabpanel" aria-labelledby="nav-about-tab">
              <h2 className='tabs_title text_dark'>{state?.game_name}</h2>
              <div className={`description ${isActive ? 'description_view' : ''}`}>
                <p>{state?.description}</p>
              </div>
              <div className='view_btn'>
                <button type='button' onClick={toggleButtonClass}>View More</button>
              </div>
            </div>
            <div className="tab-pane fade" id="nav-walkthrough" role="tabpanel" aria-labelledby="nav-walkthrough-tab">
              <h2 className='tabs_title text_dark'>{state?.game_name}</h2>
              <div className={`description ${isActive ? 'description_view' : ''}`}>
                <p>This time Santa has left his home to collect the gifts on his bike. Would you to guide him and collect all the gifts to complete all the levels? Join Santa Gift Race and be prepared to collect the presents of Santa in a completely unique racing environment! The tracks are very difficult to handle, so only those who are good at balancing bikes can complete the task perfectly. Try not to fall your bike until you achieve your goal in this brand new racing game.</p>
              </div>
              <div className='view_btn d-lg-none d-block'>
                <button type='button' onClick={toggleButtonClass}>View More</button>
              </div>
            </div>
            <div className="tab-pane fade" id="nav-controls" role="tabpanel" aria-labelledby="nav-controls-tab">
              <div className='description'>
                <p>{state?.instructions || 'Not Found'}</p>
              </div>
            </div>
          </div>
        </div>

        {/* <Card icon={<GameRemoteIcon />} title={'Trending Games'} className={'gradiunt_card overflow-hidden'} titleClass={'text_white'} btnName={'View All'} btnClass={'view_link'} >
                    <Category />
                </Card>

                <Card className={''}>
                    <PlayGame />
                </Card> */}

        <Footer />
        <GameReport
          show={show}
          setShow={setShow}
        />
      </main>
    </>
  )
}
