import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Category from '../components/Category'
import Card from '../components/Card'
import { BikeGameIcon, CricketIcon, FaviconIcon, GameRemoteIcon, HelmentIcon, NewGameIcon, TopGameIcon } from '../components/AllIcons'
import TopGames from '../components/TopGames'
import NewGame from '../components/NewGame'
import GoogleAd from '../components/GoogleAd'
import BikeGames from '../components/BikeGames'
import Footer from '../components/Footer'
import MostGames from '../components/MostGames'
import HeaderCard from '../components/HeaderCard'
import { useDispatch, useSelector } from 'react-redux'
import { getGamesCategory } from '../redux/actions/GamesCategoryAction'


export default function Home() {
    const [isActive, setIsActive] = useState(false);

    const toggleButtonClass = () => {
        setIsActive(!isActive);
    };

    // const dispatch = useDispatch()

    // useEffect(() => {
    //     dispatch(getGamesCategory(category))
    // }, [category])

    // const { data: top } = useSelector(state => state.GamesCategory)
    // console.log(top, 'top gamezx')
    return (
        <>
            <HeaderCard />

            <main className='main'>
                {/* <div className='goole_ad'>
                    <img src='assets/img/google/add.webp' alt='google add' />
                </div> */}

                <Card icon={<NewGameIcon />} title={'New Games'} className={''} titleClass={'text_dark'} btnName={'View All'} btnClass={'view_link view_text'}  >
                    <div className='card_row'>
                        <NewGame category={'/NEW'} />
                        {/* <GoogleAd /> */}
                    </div>
                </Card>

                <Card icon={<TopGameIcon />} title={'Top Games'} className={'gradiunt_card'} titleClass={'text_white'} btnName={'View All'} btnClass={'view_link'}  >
                    <TopGames category={'/TOP'} />
                </Card>

                <Card icon={<CricketIcon />} title={'Sports Games'} className={''} titleClass={'text_dark'} btnName={'View All'} btnClass={'view_link view_text'}  >
                    <div className='card_row'>
                        <NewGame category={'/Sports'} />
                        {/* <GoogleAd /> */}
                    </div>
                </Card>

                <Card icon={<BikeGameIcon />} title={'Racing Games'} className={''} titleClass={'text_dark'} btnName={'View All'} btnClass={'view_link view_text'} >
                    <BikeGames category={'/Racing'} />
                </Card>

                <Card title={'Most Played Games'} className={'bg_transparent'} titleClass={'text_dark'} btnName={'View All'} btnClass={'view_link view_text'}  >
                    {/* <MostGames /> */}
                    <TopGames category={'/TOP'} />
                </Card>

                {/* <Card icon={<HelmentIcon />} title={'Bike Games'} className={''} titleClass={'text_dark'} btnName={'View All'} btnClass={'view_link view_text'} >
                    <BikeGames category={'/Sports'} />
                </Card> */}
                <Card icon={<GameRemoteIcon />} title={'Trending Games'} className={'gradiunt_card height_auto overflow-hidden'} titleClass={'text_white'} btnName={'View All'} btnClass={'view_link'} >
                    <Category />
                </Card>

                <div className="online_game">
                    <div className="">
                        <div className="online_game_icon">
                            <FaviconIcon />
                            <h1>Play Online Games</h1>
                        </div>
                        <div className={`description ${isActive ? 'description_view' : ''}`} >
                            <p>
                                Today’s gamers think beyond the console and play station games. Free online games are their first choice now since they allow users to have fun without download, and no registration requires.
                            </p>
                            <p>
                                Powered with exploits of action-packed AI (Artificial Intelligence) and Virtual Reality, the online html5 games at our site are now more engaging, evolving, and involving than ever before.
                            </p>
                            <p>
                                At our site, we offer an extensive range of the best online games to quench your appetite for constant entertainment, curiosity, and search for action. You will never come close to a feeling of instant boredom when you
                                play online games for free on futurefusion.me.
                            </p>
                            <p>
                                Our extensive games category includes racing, action,&nbsp;adventure, sports, arcade, multiplayer, 3D and Puzzle, and many other online browser games that make sure that you will have complete fun dose with no extra
                                efforts. As the best place to enjoy free online best games, futurefusion.me enables users to play and entertain from anywhere, irrespective of their location. You can play against other players who are online either in
                                one-to-one player mode or multiplayer mode.
                            </p>
                            <p>
                                Not only means extensive fun and entertainment, but our games are also a viable means to stress-busting, reflexes improvement, and IQ enhancement. Some of&nbsp;the best free online games&nbsp;categories that we offer at
                                our site include action: fighting, monster, defense, war games, shooting, zombie; adventure games that we cover running, platform, mining, flying, and war.
                            </p>
                            <p>
                                However, the widely popular gaming categories that we include&nbsp;
                                <u>Sports and Racing.</u> In the racing game online category, you can have games like Horse Riding, Bicycle, Bike, Trekking, and, most importantly car racing. And the most popular category sport has game-like Rugby,
                                Soccer, Hockey, Cricket, Baseball, and Golf.
                            </p>
                            <p>
                                So, what are you waiting for? Choose your game category, browse through its exclusive and wide range, and get ready to keep you entertained for many hours, even for days at our free online gaming&nbsp;
                                <a href="https://futurefusion.me">futurefusion.me</a>
                            </p>
                        </div>
                        <div className='view_btn'>
                            <button type='button' onClick={toggleButtonClass}>View More</button>
                        </div>
                    </div>
                </div>

                <Footer />
            </main>
        </>
    )
}
