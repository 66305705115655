import React, { useState } from 'react'
import HeaderCard from '../components/HeaderCard'
import SideMenu from '../components/SideMenu'
import OverViewCards from '../components/OverViewCards'
import { EditUserIcon, Person, PersonF } from '../components/AllIcons'
import Footer from '../components/Footer'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { ROOT_URL } from '../utils/rootURL'

export default function EditProfile() {
    const [isGender, setIsGender] = useState('')
    const [input, setInput] = useState({ username: '', email: '', fname: '', lname: '' })
    const [errorMessage, setErrorMessage] = useState('')
    const { state } = useLocation()

    useState(() => {
        setInput({ ...input, username: state.username, email: state.email, fname: state.fname, lname: state.lname });
        setIsGender(state.gender || '')
    }, [state])

    const HandelInput = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value });
    }

    const UpdateProfile = () => {
        const inputData = {
            "gender": isGender,
            "username": input.username,
            "fname": input.fname,
            "lname": input.lname,
        }

        axios.post(`${ROOT_URL}/api/update_profile`, inputData, {
            headers: {
                Authorization: `Berear ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            setErrorMessage(res.data)
            console.log(res, 'res sssssssss')
        }).catch(err => {
            console.log(err, 'err rrrrrrr')
        })
    }

    console.log(input, isGender, state, 'valueeeeeeeeeeee')

    return (
        <>

            <HeaderCard />
            <main className='main'>
                <div className='page_layout'>
                    <SideMenu />
                    <div className='w-100'>
                        <OverViewCards />
                        <div className='game_card'>
                            <div className='row'>
                                <div className='col-xxl-6 col-xl-8'>
                                    <div className='game_card edit_profile bg_white mb-0'>
                                        <div className='title_card align-items-center'>
                                            <div className='position-relative'>
                                                <EditUserIcon />
                                                <input type='file' className='select_profile' />
                                            </div>
                                            <h2 className='text_dark'>Edit Profile</h2>
                                        </div>
                                        {errorMessage &&
                                            <p className={`${errorMessage.status === "success" ? 'success_mgs' : 'error_mgs'}`}>{errorMessage.msg}</p>
                                        }
                                        <form>
                                            <div className='d-flex align-items-center gap-2'>
                                                <div className='check_form' onClick={() => setIsGender('Male')} >
                                                    <input type='radio' className={`${isGender === 'Male' ? 'form-check-input select_form active' : 'form-check-input select_form'}`} value={'Male'} />
                                                    <div className='check_label d-flex align-items-center'>
                                                        <Person />
                                                        <p className='list_p'>Male</p>
                                                    </div>
                                                </div>
                                                <div className='check_form' onClick={() => setIsGender('Female')}>
                                                    <input type='radio' className={`${isGender === 'Female' ? 'form-check-input select_form active' : 'form-check-input select_form'}`} value={'Female'} />
                                                    <div className='check_label d-flex align-items-center'>
                                                        <PersonF />
                                                        <p className='list_p'>Female</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='login_form mt-3'>
                                                {/* <p className='note_massage massage_set'>This field is required</p> */}
                                                <input type='text' className='form-control input_box' value={input.username} onChange={HandelInput} name='username' required />
                                                <label className='form-lable'>Username </label>
                                            </div>
                                            <div className='login_form mt-3'>
                                                {/* <p className='note_massage massage_set'>This field is required</p> */}
                                                <input type='text' className='form-control input_box' value={input.fname} onChange={HandelInput} name='fname' required />
                                                <label className='form-lable'>First Name </label>
                                            </div>
                                            <div className='login_form mt-3'>
                                                {/* <p className='note_massage massage_set'>This field is required</p> */}
                                                <input type='text' className='form-control input_box' value={input.lname} onChange={HandelInput} name='lname' required />
                                                <label className='form-lable'>Last Name</label>
                                            </div>
                                            <div className='login_form mt-3'>
                                                {/* <p className='note_massage massage_set'>This field is required</p> */}
                                                <input type='text' className='form-control input_box' value={input.email} onChange={HandelInput} name='email' required />
                                                <label className='form-lable'>Email</label>
                                            </div>
                                        </form>
                                        <button className='btn sign_btn mt-3' onClick={UpdateProfile}>
                                            Update Profile
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <Footer />
            </main >
        </>
    )
}
